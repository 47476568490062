import React, { useState, useEffect, useCallback } from "react";
import "./css/EventModal.css"; // Import your custom CSS for styling
import {
  Phone,
  Mail,
  Edit2,
  User,
  Building2,
  CalendarCheck2,
  LaptopMinimalCheck,
  Copy,
  IndianRupee,
  Trash2,
  FileText,
  Eye,
  EyeOff,
} from "lucide-react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { app, storage } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { set } from "lodash";

const EventModal = ({
  event,
  selectedSlotStartTime,
  selectedSlotEndTime,
  isOpen,
  onClose,
  onSave,
  onDelete,
  isUpdate,
  candidates,
}) => {
  const colors = [
    "rgb(111, 100, 74)", // rgb(171, 160, 134) darkened
    "rgb(132, 80, 167)", // rgb(192, 140, 227) darkened
    "rgb(99, 121, 93)", // rgb(159, 181, 153) darkened
    "rgb(112, 123, 118)", // rgb(172, 183, 178) darkened
    "rgb(122, 134, 115)", // rgb(182, 194, 175) darkened
    "rgb(143, 90, 178)", // rgb(203, 150, 238) darkened
    "rgb(98, 128, 124)", // rgb(158, 188, 184) darkened
    "rgb(132, 185, 106)", // rgb(192, 245, 166) darkened
    "rgb(126, 86, 146)", // rgb(186, 146, 206) darkened
    "rgb(142, 180, 87)", // rgb(202, 240, 147) darkened
    "rgb(130, 91, 76)", // rgb(190, 151, 136) darkened
    "rgb(115, 184, 133)", // rgb(175, 244, 193) darkened
    "rgb(139, 0, 0)", // Dark Red
    "rgb(255, 20, 147)", // Deep Pink
    "rgb(0, 0, 128)", // Navy Blue
    "rgb(75, 0, 130)", // Indigo (Dark Purple)
    "rgb(128, 0, 128)", // Purple
    "rgb(102, 51, 153)", // Dark Orchid
    "rgb(128, 0, 0)", // Maroon
    "rgb(60, 0, 60)", // Dark Magenta
    "rgb(64, 224, 208)", // Turquoise
  ];
  const db = getFirestore(app);
  const auth = getAuth(app);
  const selectedEmail = useSelector((state) => state.candidates.selectedEmail);

  const updateSeletecEmail = useCallback(()=>{
    setEventData((prevData) => ({
      ...prevData,
      ['resourceEmail']:selectedEmail // Handle checkbox state
    }));
  },[selectedEmail]);

  const [eventData, setEventData] = useState({
    title: "",
    date: new Date().toISOString().split("T")[0], // Default to today's date
    startTime: new Date().toISOString().split("T")[1].substring(0, 5), // Default to current time
    endTime: new Date().toISOString().split("T")[1].substring(0, 5), // Default to current time
    color: colors[0], // Default to the first color
    companyName: "",
    panelName: "",
    candidateEmail: "",
    candidateName: "",
    panelEmail: "",
    resourceEmail: selectedEmail ?? "",
    roundDetails: "",
    isCompleted: false, // Initialize isCompleted state
    meetingLink: "", // New field for meeting link
    organizer: "", // New field for organizer
  });

  const [isEditing, setIsEditing] = useState(!event ? true : false); // Start in edit mode if no event
  // const {candidates} = useSelector(state => state.candidates.candidates);
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Update eventData when the event prop changes
  useEffect(() => {
    const fetchCandidates = async () => {
      // setLoader(true);
      try {
        const querySnapshot = await getDocs(collection(db, "BDProfiles"));
        const candidatesData = querySnapshot.docs.map((doc) => doc.data());
        dispatch(setCandidates(candidatesData));
        // addToast('Success', 'success');
        // setLoader(false);
      } catch (error) {
        // addToast(error.error, "error");
        // setLoader(false);
      }
    };

    if (event) {
      setIsEditing(false);
      const startDate = new Date(event?.start);
      const endDate = new Date(event?.end);
      const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
      const istStart = new Date(startDate.getTime() + istOffset);
      const istEnd = new Date(endDate.getTime() + istOffset);

      setEventData({
        title: event?.title || "",
        date: istStart.toISOString().split("T")[0],
        startTime: istStart.toISOString().split("T")[1].substring(0, 5),
        endTime: istEnd.toISOString().split("T")[1].substring(0, 5),
        color: event?.color || colors[0], // Keep existing color for existing events
        companyName: event?.companyName || "",
        panelName: event?.panelName || "",
        candidateName: event?.candidateName || "",
        candidateEmail: event?.candidateEmail || "",
        panelEmail: event?.panelEmail || "",
        resourceEmail: event?.resourceEmail || "",
        roundDetails: event?.roundDetails || "",
        isCompleted: event?.isCompleted || false, // Keep existing isCompleted status
        meetingLink: event?.meetingLink || "", // New field for meeting link
        organizer: event?.organizer || "", // New field for organizer
      });
    } else if (event == null) {
      setIsEditing(true);
    } else if (event !== null) {
      setIsEditing(false);
    } else if (selectedSlotStartTime && selectedSlotEndTime) {
      const today = new Date();
      const startTimeFromProps = new Date(selectedSlotStartTime).getHours();
      const startTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        startTimeFromProps,
        0,
        0
      );
      const endTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        startTimeFromProps + 1,
        0,
        0
      );
      const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
      const istStart = new Date(startTime.getTime() + istOffset);
      const istEnd = new Date(endTime.getTime() + istOffset);
      eventData.startTime = istStart
        .toISOString()
        .split("T")[1]
        .substring(0, 5);
      eventData.endTime = istEnd.toISOString().split("T")[1].substring(0, 5);
    }

    if (!isUpdate) {
      setEventData({
        title: "",
        date: new Date().toISOString().split("T")[0], // Default to today's date
        startTime: new Date().toISOString().split("T")[1].substring(0, 5), // Default to current time
        endTime: new Date().toISOString().split("T")[1].substring(0, 5), // Default to current time
        color: colors[0], // Default to the first color
        companyName: "",
        panelName: "",
        panelEmail: "",
        candidateName: "",
        roundDetails: "",
        resourceEmail: "",
        candidateEmail: "",
        isCompleted: false, // Initialize isCompleted state
        meetingLink: "", // New field for meeting link
        organizer: "", // New field for organizer
      });
    }

    scrollToTop();
    fetchCandidates();
    updateSeletecEmail();
  }, [event, selectedSlotStartTime, selectedSlotEndTime, isUpdate]);

  if (!isOpen) return null; // Don't render if not open

  // Generic handler for input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const candidateEmail =
      candidates.filter((candidate) => candidate.fullName === value)[0]?.emailId ??
      "";
    if (name == "candidateName") {
      setEventData((prevData) => ({
        ...prevData,
        ["candidateEmail"]: candidateEmail, // Handle checkbox state
      }));
    }
    setEventData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox state
    }));
  };

  const handleDelete = () => {
    onDelete(event); // Pass the event to the onDelete callback
    onClose(); // Pass null to indicate deletion
  };

  const handleClose = () => {
    // Reset eventData to its default state instead of setting it to null
    setEventData({
      title: "",
      date: new Date().toISOString().split("T")[0],
      startTime: new Date().toISOString().split("T")[1].substring(0, 5),
      endTime: new Date().toISOString().split("T")[1].substring(0, 5),
      color: colors[0],
      companyName: "",
      panelName: "",
      candidateEmail: "",
      candidateName: "",
      resourceEmail: "",
      panelEmail: "",
      roundDetails: "",
      isCompleted: false,
      meetingLink: "",
      organizer: "",
    });
    onClose(); // Close the modal
  };

  const handleSave = () => {
    if (!eventData) return;
    let {
      date,
      start,
      candidateName,
      candidateEmail,
      end,
      startTime,
      resourceEmail,
      endTime,
      title,
      companyName,
      panelName,
      panelEmail,
      roundDetails,
      isCompleted, // Include isCompleted in the save
      meetingLink, // Include meeting link in the save
      organizer, // Include organizer in the save
    } = eventData;

    // Randomly assign a color if creating a new event
    const color = event
      ? eventData.color
      : colors[Math.floor(Math.random() * colors.length)];

    const startT = new Date(`${date}T${startTime}`);
    const endT = new Date(`${date}T${endTime}`);
    date = new Date(date);

    // Create an object with all the necessary fields
    const eventToSave = {
      title: title || "",
      date: date,
      start: startT,
      end: endT,
      color: color, // Use the randomly assigned color or existing color
      companyName: companyName || "",
      resourceEmail: resourceEmail || "",
      panelName: panelName || "",
      panelEmail: panelEmail || "",
      candidateName: candidateName || "",
      candidateEmail: candidateEmail || "",
      roundDetails: roundDetails || "",
      isCompleted: isCompleted, // Save isCompleted status
      meetingLink: meetingLink || "", // Save meeting link
      organizer: organizer || "", // Save organizer
    };

    onSave(eventToSave); // Pass the complete event data to the onSave callback
    onClose(); // Close the modal after saving
  };

  const clickIsEdit = () => {
    setIsEditing(true);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className=" text-xl font-bold text-[#0071bd] mb-1 bg-gray-100 p-2 mt-4 rounded-md">
          {event?.title || "Create Event"}
        </h2>

        {/* Display Event Details in Non-Editable Mode */}
        {!isEditing && (
          <div className="event-details p-6 bg-white rounded-lg shadow-lg border border-gray-200">
            <div className="flex items-center mb-4">
              <CalendarCheck2 className="w-6 h-6 text-[#0071bd] mr-2" />
              <p className="text-xl font-semibold text-gray-800">
                {`${new Date(eventData.date).toLocaleDateString("en-US", {
                  weekday: "short",
                })} - ${new Date(eventData.date).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })} ${new Date(
                  `${eventData.date}T${eventData.startTime}`
                ).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })} to ${new Date(
                  `${eventData.date}T${eventData.endTime}`
                ).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}`}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <Mail className="w-5 h-5 text-[#0071bd] mr-2" />
              <p className="text-gray-700 font-medium">
                {eventData?.candidateEmail}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <User className="w-5 h-5 text-[#0071bd] mr-2" />
              <p className="text-gray-700 font-medium">
                {eventData?.candidateName}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <Building2 className="w-5 h-5 text-[#0071bd] mr-2" />
              <p className="text-gray-700 font-medium">
                {eventData?.companyName}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <LaptopMinimalCheck className="w-5 h-5 text-[#0071bd] mr-2" />
              <p className="text-gray-700 font-medium">
                {eventData?.roundDetails}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <User className="w-5 h-5 text-[#0071bd] mr-2" />
              <p className="text-gray-700 font-medium">
                BY : &nbsp;
                {eventData?.resourceEmail
                  .toString()
                  .split("@")[0]
                  .toUpperCase()}
              </p>
            </div>

            <p className="text-gray-700 mb-4 font-medium">
              {eventData?.isCompleted ? "Completed" : "Not Completed"}
            </p>

            <div className="flex justify-between mt-4">
              <button
                className="edit-button bg-[#0071bd] text-white px-5 py-2 rounded-lg shadow transition duration-200"
                onClick={clickIsEdit}
              >
                Edit
              </button>
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg shadow hover:bg-gray-400 transition duration-200 cancel-button "
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Action buttons for editing or saving */}
        {isEditing && (
          <form onSubmit={handleSave}>

         
          <div>
            {/* Event details form for editing */}
            <div className="event-details">
              <label>Event Title:</label>
              <input
                type="text"
                name="title"
                required
                value={eventData?.title ?? ""}
                onChange={handleChange}
                className="event-input"
              />
            </div>
            <div className="event-details flex-row">
              <div className="event-date-color w-1/2">
                <label>Select Candidate</label>
                <input
                  type="text"
                  name="candidateName"
                  list="candidates"
                  required
                  value={eventData?.candidateName ?? ""}
                  onChange={handleChange}
                  className="event-input"
                />
                <datalist id="candidates">
                  {candidates.map((candidate, index) => (
                    <option key={index} value={candidate.fullName}>
                      {candidate.fullName}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="flex-row">
              <div className="event-date-color">
                <label>Company Name:</label>
                <input
                  type="text"
                  name="companyName"
                  required
                  value={eventData?.companyName ?? ""}
                  onChange={handleChange}
                  className="event-input"
                />
              </div>
              <div className="event-date-color">
                <label>Panel Name:</label>
                <input
                  type="text"
                  name="panelName"
                  value={eventData?.panelName ?? ""}
                  onChange={handleChange}
                  className="event-input"
                />
              </div>
            </div>

            <div className="flex-row">
              <div className="event-date-color">
                <label>Panel Email:</label>
                <input
                  type="email"
                  name="panelEmail"
                  value={eventData?.panelEmail ?? ""}
                  onChange={handleChange}
                  className="event-input"
                />
              </div>
              <div className="event-date-color">
                <label>Round Details:</label>
                <select
                  name="roundDetails"
                  value={eventData?.roundDetails ?? ""}
                  onChange={handleChange}
                  required
                  className="event-input"
                >
                  <option value="">Select Round</option>
                  <option value="L1 Interview">L1 Interview</option>
                  <option value="L2 Interview">L2 Interview</option>
                  <option value="Final/Client Round">Final/Client Round</option>
                  <option value="HR Round">HR Round</option>
                </select>
              </div>
            </div>

            <div className="event-details flex-row">
              <div className="event-date-color w-1/2">
                <label>Date:</label>
                <input
                  type="date"
                  name="date"
                  value={eventData?.date ?? ""}
                  onChange={handleChange}
                  required
                  className="event-input"
                />
              </div>

              {/* Styling for isCompleted toggle */}
              <div className="event-date-color w-1/2">
                <label className="block">Completed:</label>
                <div className="relative inline-flex items-center mt-1">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      name="isCompleted"
                      checked={eventData?.isCompleted ?? false}
                      onChange={handleChange}
                      className="sr-only"
                    />
                    <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
                    <div
                      className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                        eventData?.isCompleted
                          ? "translate-x-full bg-[#0071bd]"
                          : "bg-gray-500"
                      }`}
                    ></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="event-details flex-row">
              <div className="event-time">
                <label>Start Time:</label>
                <input
                  type="time"
                  name="startTime"
                  value={eventData?.startTime ?? ""}
                  onChange={handleChange}
                  className="event-input"
                  required
                />
              </div>
              <div className="event-time">
                <label>End Time:</label>
                <input
                  type="time"
                  name="endTime"
                  value={eventData?.endTime ?? ""}
                  onChange={handleChange}
                  className="event-input"
                  required
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button type="submit" className="join-button" >
                Save
              </button>
              {event && event?.id && (
                <button
                  disabled={!event}
                  className="delete-button"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              )}
              <button className="cancel-button" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EventModal;
