import React from 'react';
import { Menu, LogOut } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/authSlice';

interface MobileHeaderProps {
  toggleSidebar: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSidebar }) => {
  const dispatch = useDispatch();

  return (
    <header className="fixed top-0 left-0 right-0 h-14 bg-[#0071bd] text-white shadow-lg z-50">
      <div className="flex items-center justify-between h-full px-3">
        <button
          onClick={toggleSidebar}
          className="p-2 hover:bg-[#0062a3] rounded-lg"
        >
          <Menu className="w-5 h-5" />
        </button>
        <h1 className="text-lg font-semibold">Candidate Management</h1>
        <button
          onClick={() => dispatch(logout())}
          className="p-2 hover:bg-[#0062a3] rounded-lg"
        >
          <LogOut className="w-5 h-5" />
        </button>
      </div>
    </header>
  );
};

export default MobileHeader;