import React, { useState, memo, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { app } from "../firebase";
import { ClipboardCheck } from "lucide-react"; // Import the copied icon

interface Props {
  addToast: any; // Adjust the type as needed
  setLoader: any; // Adjust the type as needed
}

const CoefficientCalculator: React.FC<Props> = ({ addToast, setLoader }) => {
  const [amount, setAmount] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false); // State for copy status
  const top1plus2big = 0.4709;
  const top1puls2small = 0.5291;
  const db = getFirestore(app);
  const auth = getAuth(app);

  const coefficient1 = (amount * top1plus2big).toFixed(2);
  const coefficient2 = (amount * top1puls2small).toFixed(2);

  const [isCopied1, setIsCopied1] = useState<boolean>(false); // State for first coefficient copy status
  const [isCopied2, setIsCopied2] = useState<boolean>(false);

  const [capitalData, updatedCapitalData] = useState<any>(0);

  useEffect(() => {
    const fetchCapital = async () => {
      setLoader(true);
      try {
        const querySnapshot = await getDocs(collection(db, "currentCapital"));
        const capitalData: any = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        updatedCapitalData(capitalData[0]);
        setAmount(Number(capitalData[0].capital));
        addToast("Data fetched successfully", "success");
        setLoader(false);
      } catch (error) {
        addToast(error.message, "error");
        setLoader(false);
      }
    };
    fetchCapital();
  }, []);

  const updateFirebaseRecord = async (newAmount: number) => {
    try {
      await updateDoc(doc(db, "currentCapital", capitalData?.id), {
        capital: newAmount,
      });
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = Number(e.target.value);
    setAmount(newAmount);
    updateFirebaseRecord(newAmount);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleCopy1 = () => {
    navigator.clipboard
      .writeText(coefficient1)
      .then(() => {
        setIsCopied1(true);
        setTimeout(() => setIsCopied1(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => addToast("Failed to copy!", "error"));
  };

  const handleCopy2 = () => {
    navigator.clipboard
      .writeText(coefficient2)
      .then(() => {
        setIsCopied2(true);
        setTimeout(() => setIsCopied2(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => addToast("Failed to copy!", "error"));
  };
  return (
    <div className="relative container mx-auto m-0 p-3 bg-gray-50 rounded-lg shadow-lg py-6">
      <p className="font-bold mb-4 text-2xl">
        <span>
          {"1) "}
          {coefficient1}
        </span>{" "}
        &nbsp;&nbsp;{" "}
        <span>
          {"2) "}
          {coefficient2}
        </span>
        {/* <button onClick={handleCopy} className="ml-4">  
          <ClipboardCheck className={`text-lg ${isCopied ? 'text-green-500' : 'text-gray-700'}`} />  
        </button>   */}
      </p>
      <h1 className="text-lg font-bold text-[#0071bd] mb-6">
        Active Capital & Co-Efficients
      </h1>
      <div className="mb-2">
        <label
          htmlFor="amount"
          className="block text-md font-medium text-gray-800"
        >
          Enter Amount:
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={handleAmountChange}
          className="mt-2 block font-bold w-full text-md p-2 py-6 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-[#0071bd] transition duration-200"
        />
      </div>
      <div className="p-2 bg-white shadow-lg rounded-lg">
        <h2 className="text-xl font-semibold mb-2 text-[#0071bd]">
          Coefficients
        </h2>
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between items-center p-2 border border-gray-300 rounded-lg hover:shadow-md transition-shadow duration-200">
            <span className="font-medium">1. TOP1 + 2BIG ({top1plus2big})</span>

            <div className="flex justify-between items-center p-2 border border-gray-300 rounded-lg hover:shadow-md transition-shadow duration-200">
              <button onClick={handleCopy1} className="flex items-center">
                <span className="text-[#0071bd] font-bold">{coefficient1}</span>
                <ClipboardCheck
                  className={`ml-2 text-lg ${
                    isCopied1 ? "text-green-700" : "text-gray-400"
                  }`}
                />
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center p-2 border border-gray-300 rounded-lg hover:shadow-md transition-shadow duration-200">
            <span className="font-medium">
              2. TOP1 + 2SMALL ({top1puls2small})
            </span>

            <div className="flex justify-between items-center p-2 border border-gray-300 rounded-lg hover:shadow-md transition-shadow duration-200">
              <button onClick={handleCopy2} className="flex items-center">
                <span className="text-[#0071bd] font-bold">{coefficient2}</span>
                <ClipboardCheck
                  className={`ml-2 text-lg ${
                    isCopied2 ? "text-green-700" : "text-gray-400"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CoefficientCalculator);
