import React from "react";  
import { Phone, Clock, Edit } from "lucide-react"; // Importing the Pencil (Edit) icon  
import {  
  CreditArrow,  
  CustomIconCreditCardIcon,  
  CustomIconVisaIcon,  
  DebitArrow,  
  GooglePayIcon,  
  HandCashIcon,  
  InternetBankingIcon,  
  NetBanking,  
  PhonePeIconNew,  
} from "./Icons";  
import { formatAmount } from "./utilities";  
import "../components/css/TransitionCard.css";  

const TransactionCard = ({ transaction, onEdit, onDelete }) => {  
  // Handler functions to handle edit and delete actions  
  const handleEdit = () =>{
    onEdit(transaction);  
  }  
  const handleDelete = () => onDelete(transaction.id);  

  // Payment icons mapping for cleaner icon rendering  
  const paymentIcons = {  
    phonepe: <PhonePeIconNew className="w-5 h-5 text-gray-600 mr-1" />,  
    netbanking: <InternetBankingIcon className="w-8 h-8 text-gray-600 mr-4" />,  
    googlepay: <GooglePayIcon className="w-5 h-5 text-gray-600 mr-1" />, 
    card : <><CustomIconCreditCardIcon className="w-5 h-5 text-gray-600 mr-1" /> 
    &nbsp;<CustomIconVisaIcon  className="w-5 h-5 text-gray-600" /></>,
    cash: <HandCashIcon className="w-5 h-5 text-gray-600 mr-1" />
  };  

  // Converts dateTime to formatted date and time  
  const formatDateTime = (dateTime) => {  
    const date = new Date(dateTime);  
    return {  
      date: date.toLocaleDateString("en-US", {  
        day: "numeric",  
        month: "short",  
        year: "numeric",  
      }),  
      time: date.toLocaleTimeString("en-US", {  
        hour: "numeric",  
        minute: "numeric",  
        hour12: true,  
      }),  
    };  
  };  

  const { date, time } = formatDateTime(transaction.sender.dateTime);  
  const paymentIcon =  
    paymentIcons[transaction.sender.mode.toLowerCase().replace(/ /g, "")] ||  
    null;  

  return (  
      <div className="bg-white shadow-lg rounded-lg px-1 w-full max-w-md mx-auto my-0 mt-0 nb-0 border border-gray-200 relative">  
        {/* Edit Icon */}  
        <div className="absolute top-2 right-2 cursor-pointer" onClick={handleEdit}>  
          <Edit className="w-5 h-5 text-orange-600 hover:text-blue-500" />  
        </div>  
  
        <div className="flex items-center justify-between">  
          {/* Left Side: Sender Information */}  
          <div className="flex-1 p-2 min-w-0"> {/* Added min-w-0 for text truncation */}  
            <div className="flex flex-col">  
              {/* Date */}  
              <p className="text-gray-600 text-xs font-bold flex items-center mb-2">  
                <Clock className="w-3 h-3 mr-1" />  
                <span className="truncate">{date}</span>  
              </p>  
              
              {/* Name and Phone Container */}  
              <div className="space-y-1">  
                {/* Name with Arrow */}  
                <div className="flex items-center space-x-1">  
                  <DebitArrow className="w-4 h-4 flex-shrink-0" />  
                  <span className="text-sm font-semibold truncate">  
                    {transaction.sender.name}  
                  </span>  
                </div>  
                
                {/* Phone Number */}  
                <div className="flex items-center space-x-1">  
                  <Phone className="w-3 h-3 flex-shrink-0" />  
                  <a href={`tel:${transaction.sender.mobile}`}  
                     className="text-xs text-gray-500 font-bold truncate hover:text-blue-500">  
                    {transaction.sender.mobile}  
                  </a>  
                </div>  
                
                {/* Amount */}  
                <div className="flex items-center text-green-600 font-bold scaling-icon">  
                  {paymentIcon}    
                </div>  
              </div>  
            </div>  
          </div>  
  
          {/* Vertical Separator */}  
          <div className="flex-shrink-0 mx-2">  
            <div className="relative h-[100px]">  
              <div className="absolute left-1/2 transform -translate-x-1/2 w-0.5 bg-gray-300 h-full" />  
              <div className="absolute left-1/2 transform -translate-x-1/2 -top-1 w-2.5 h-2.5 rounded-full bg-[#0071ad] z-10 shadow-sm" />  
              <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-1 w-2.5 h-2.5 rounded-full bg-[#0071ad] z-10 shadow-sm" />  
            </div>  
          </div>  
  
          {/* Right Side: Receiver Information */}  
          <div className="flex-1 p-2 mt-4 min-w-0"> {/* Added min-w-0 for text truncation */}  
            <div className="flex flex-col items-end">  
              {/* Time */}  
              <p className="text-gray-500 text-xs font-bold mb-0 truncate">  
                {time}  
              </p>  
              
              {/* Name and Phone Container */}  
              <div className="space-y-1 w-full">  
                {/* Name with Arrow */}  
                <div className="flex items-center justify-end space-x-1">  
                  <span className="text-sm font-semibold truncate">  
                    {transaction.receiver.name}  
                  </span>  
                  <CreditArrow className="w-4 h-4 flex-shrink-0" />  
                </div>  
                
                {/* Phone Number */}  
                <div className="flex items-center justify-end space-x-1">  
                  <Phone className="w-3 h-3 flex-shrink-0" />  
                  <a href={`tel:${transaction.receiver.mobile}`}  
                     className="text-xs text-gray-500 font-bold truncate hover:text-blue-500">  
                    {transaction.receiver.mobile}  
                  </a>  
                </div>  
              </div>  
              <div className="flex items-center justify-end mt-1 text-green-600 font-bold">  
              <span className="text-sm ml-1 truncate">  
                    ₹{formatAmount(transaction.sender.amount)}  
                  </span>
              </div>
            </div>  
          </div>  
        </div>  
      </div>    
  );  
};  

export default TransactionCard;