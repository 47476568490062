import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Candidate } from '../../types/candidate';

interface CandidateTableProps {
  candidates: Candidate[];
}

const CandidateTable: React.FC<CandidateTableProps> = ({ candidates }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const isSuperAdmin = user?.role === 'SUPER_ADMIN';

  return (
    <div className="bg-white rounded-lg shadow overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Technology</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            {isSuperAdmin && (
              <>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid Amount</th>
              </>
            )}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {candidates.map((candidate) => (
            <tr key={candidate.id}>
              <td className="px-6 py-4 whitespace-nowrap">{candidate.fullName}</td>
              <td className="px-6 py-4 whitespace-nowrap">{candidate.emailId}</td>
              <td className="px-6 py-4 whitespace-nowrap">{candidate.technology}</td>
              <td className="px-6 py-4 whitespace-nowrap">{candidate.interviewProgress}</td>
              {isSuperAdmin && (
                <>
                  <td className="px-6 py-4 whitespace-nowrap">{candidate.balance}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{candidate.paidAmount}</td>
                </>
              )}
              <td className="px-6 py-4 whitespace-nowrap">
                <button className="text-[#0071bd] hover:text-[#0062a3] mr-3">Edit</button>
                <button className="text-red-600 hover:text-red-800">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CandidateTable;