import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { UserPlus } from "lucide-react";
import React, { useEffect, useState } from "react";
import "../components/css/VendorPayments.css";
import TransactionCard from "../components/TransitionCard"; // Ensure this import matches the file name
import { app } from "../firebase";
import AddOrEditTransaction from "./AddOrEditTransaction";
import AddVendorPopup from "./AddOrEditVendor";
import { WhiteSVGIcon } from "./Icons";

const VendorPayments = ({ setLoader, addToast, user }) => {
  const transactionData = [
    {
      id: "1", // Add an id for each transaction
      sender: {
        name: "John Doe",
        dateTime: "12/12/2024 10:30 AM",
        mobile: "+91 98765 43210",
        amount: "5000",
        mode: "netbanking",
        time: "12 hours ago",
      },
      receiver: {
        name: "Amazon Pay",
        mobile: "+91 87654 32109",
      },
      vendorName: "",
      vendorEmailId: "",
      vendorMobile: "",
    },
    {
      id: "2", // Add an id for each transaction
      sender: {
        name: "Jane Smith",
        mobile: "+91 99876 54321",
        dateTime: "12/20/2024 10:30 AM",
        amount: "180",
        mode: "Phonepe",
        time: "1 day ago",
      },
      receiver: {
        name: "SIDHI CAFÉ",
        mobile: "+91 56543 21098",
      },
    },
    // Add more transactions as needed
  ];
  const [selectedVendor, setSelectedVendor] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [transactionOpenPopup, setTransactionOpenPopup] = useState(false); // State to control transaction popup visibility
  const [selectedTransaction, setSelectedTransaction] = useState(null); // State to hold the selected transaction data for editing
  const [scrollPosition, setScrollPosition] = useState(0); // State to hold the current scroll position
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0); // State to hold the total transaction amount
  const [displayedAmount, setDisplayedAmount] = useState(0); // State to hold the displayed amount for the progress bar
  const db = getFirestore(app);
  const auth = getAuth(app);

  const setScrollPositionDOM = (position) => {
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSaveTransaction = async (transaction) => {
    const transactionData = {
      id: transaction.id,
      senderName: transaction.sender?.name,
      senderMobile: transaction.sender?.mobile,
      senderDateTime: transaction.sender?.dateTime,
      senderAmount: transaction.sender?.amount,
      senderMode: transaction.sender?.mode,
      receiverName: transaction.receiver?.name,
      receiverMobile: transaction.receiver?.mobile,
      vendorName: transaction?.vendorName,
      vendorEmailId: transaction?.vendorEmailId,
      vendorMobile: transaction?.vendorMobile,
      transactionDateTime: Timestamp.now(),
    };
    try {
      if (transaction?.id) {
        await updateDoc(
          doc(db, "BDTransactions", transaction.id),
          transactionData
        );
      } else {
        const newTransactionRef = doc(collection(db, "BDTransactions"));
        delete transactionData?.id;
        await setDoc(newTransactionRef, {
          id: newTransactionRef.id,
          ...transactionData,
        });
      }
      addToast(
        transaction?.id
          ? "Transaction updated successfully"
          : "Transaction saved successfully",
        "success"
      );
      setSelectedTransaction(null); // Reset the selected transaction data after saving
      handleVendorSelect(transaction.vendorEmailId); // Update the transactions list for the selected vendor
      setTransactionOpenPopup(false); // Close the transaction popup
    } catch (error) {
      console.error("Error saving transaction:", error);
      addToast("Error saving transaction", "error");
    }
  };

  const handleDeleteTransaction = async (id) => {
    try {
      const transactionRef = doc(collection(db, "BDTransactions"), id);
      await deleteDoc(transactionRef);
      addToast("Transaction deleted successfully", "success");
      setTransactionOpenPopup(false); // Close the transaction popup
      getVendoreTransactionsByEmail(selectedVendor); // Update the transactions list for the selected vendor
    } catch (error) {
      console.error("Error deleting transaction:", error);
      addToast("Error deleting transaction", "error");
    }
  };

  const handleEditTransaction = (transaction) => {
    // Capture the current scroll position
    const scrollTop = window.scrollY; // Get the current scroll position
    // Optionally, store the scroll position in state if needed
    setScrollPosition(scrollTop); // Assuming you have a state setup for this
    setSelectedTransaction(transaction);
    setTransactionOpenPopup(true);
  };

  const handleVendorSelect = (vendorEmail) => {
    setSelectedVendor(vendorEmail);
    getVendoreTransactionsByEmail(vendorEmail);
  };

  useEffect(() => {
    setLoader(true); // Show the loader while fetching data
    // Subscribe to vendor changes
    const unsubscribeFromVendors = onSnapshot(
      collection(db, "BDVendors"),
      (querySnapshot) => {
        const vendors = querySnapshot.docs.map((doc) => doc.data());
        setVendorsList(vendors);
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors
      }
    );

    // With additional validation, error handling, and loading states
    const unsubscribeFromTransactions = onSnapshot(
      query(
        collection(db, "BDTransactions"),
        where("vendorEmailId", "==", selectedVendor || "")
      ),
      (querySnapshot) => {
        try {
          setLoader(true); // Show loader while processing

          // Transform and validate the data
          const decryptedTransactions = querySnapshot.docs
            .filter((doc) => doc.exists())
            .map((doc) => {
              const transaction = doc.data();

              // Validate date format
              const dateTime = transaction.senderDateTime || "";
              const isValidDate = !isNaN(new Date(dateTime).getTime());

              return {
                id: doc.id,
                sender: {
                  name: transaction.senderName || "",
                  dateTime: isValidDate ? dateTime : new Date().toISOString(),
                  mobile: transaction.senderMobile || "",
                  amount: transaction.senderAmount || "0",
                  mode: transaction.senderMode || "",
                },
                receiver: {
                  name: transaction.receiverName || "",
                  mobile: transaction.receiverMobile || "",
                },
                vendorName: transaction.vendorName || "",
                vendorEmailId: transaction.vendorEmailId || "",
                vendorMobile: transaction.vendorMobile || "",
              };
            });

          // Sort with error handling
          try {
            decryptedTransactions.sort((a, b) => {
              const dateA = new Date(a.sender.dateTime).getTime();
              const dateB = new Date(b.sender.dateTime).getTime();
              return dateA - dateB;
            });
          } catch (sortError) {
            console.error("Error sorting transactions:", sortError);
            addToast("Error sorting transactions", "warning");
          }

          // Calculate total with validation
          const totalAmount = decryptedTransactions.reduce((acc, curr) => {
            const amount = parseFloat(curr.sender.amount);
            return acc + (isNaN(amount) ? 0 : amount);
          }, 0);

          // Update states with validation
          if (decryptedTransactions.length > 0) {
            setIsEmpty(false);
            setTransactions(decryptedTransactions);
            setTotalTransactionAmount(totalAmount);

            // Optional: Log success
            console.log(
              `Processed ${decryptedTransactions.length} transactions`
            );
            console.log(`Total amount: ${totalAmount}`);
          } else {
            setIsEmpty(true);
            setTransactions([]);
            setTotalTransactionAmount(0);

            // Optional: Log empty state
            console.log("No transactions found for vendor:", selectedVendor);
          }
        } catch (error) {
          console.error("Error processing transactions:", error);
          addToast("Error processing transaction data", "error");
          setIsEmpty(true);
          setTransactions([]);
          setTotalTransactionAmount(0);
        } finally {
          setLoader(false); // Always hide loader
        }
      },
      (error) => {
        console.error("Subscription error:", error);
        addToast(`Error fetching transactions: ${error.message}`, "error");
        setLoader(false);
        setIsEmpty(true);
        setTransactions([]);
        setTotalTransactionAmount(0);
      }
    );

    if (user?.role == "VENDOR") {
      handleVendorSelect(user?.emailId);
    }
    const startCounting = () => {
      const duration = 50; // Duration in milliseconds
      const totalSteps = 80; // Total number of steps for smoother updates
      const increment = totalTransactionAmount / totalSteps; // Increment per step

      let currentAmount = 0;
      let step = 0;

      const countUp = () => {
        if (step < totalSteps) {
          currentAmount += increment; // Increment the current amount
          if (currentAmount >= totalTransactionAmount) {
            currentAmount = totalTransactionAmount; // Ensure we don't exceed the final amount
          }
          setDisplayedAmount(Math.floor(currentAmount)); // Update displayed amount
          step++; // Move to the next step
          requestAnimationFrame(countUp); // Request the next frame
        } else {
          setDisplayedAmount(totalTransactionAmount); // Ensure final amount is set
        }
      };

      requestAnimationFrame(countUp); // Start the counting animation
    };

    startCounting();
    setLoader(false); // Hide the loader after fetching data
    return () => {
      unsubscribeFromVendors(); // Cleanup subscription on unmount
      unsubscribeFromTransactions();
    };
  }, [db, addToast, totalTransactionAmount]);

  const openModal = () => setIsModalOpen(true); // Function to open the modal
  const closeModal = () => {
    setIsModalOpen(false); // Function to close the modal
    setScrollPosition(scrollPosition);
  };

  const saveVendor = async (id, vendorData) => {
    try {
      if (id) {
        // Update existing vendor
        console.log(`Updating vendor with ID ${id}`, vendorData);
        await updateDoc(doc(db, "BDVendors", id), vendorData);
      } else {
        // Add new vendor
        console.log("Adding new vendor", vendorData);
        await setDoc(doc(collection(db, "BDVendors")), vendorData);
      }
      setScrollPositionDOM(scrollPosition);
    } catch (error) {
      console.error("Error saving vendor:", error);
      addToast("Error saving vendor", "error");
    }
  };

  const getVendoreTransactionsByEmail = async (vendorEmail) => {
    try {
      setLoader(true); // Show the loader while fetching data
      const vendorTransactions = await getTransactionsByEmail(vendorEmail);

      // Process each transaction to create the desired final structure
      const decryptedTransactions = vendorTransactions.map((transaction) => {
        return {
          id: transaction.id,
          sender: {
            name: transaction.senderName || "",
            dateTime: transaction.senderDateTime || "",
            mobile: transaction.senderMobile || "",
            amount: transaction.senderAmount || "",
            mode: transaction.senderMode || "",
          },
          receiver: {
            name: transaction.receiverName || "",
            mobile: transaction.receiverMobile || "",
          },
          vendorName: transaction.vendorName || "",
          vendorEmailId: transaction.vendorEmailId || "",
          vendorMobile: transaction.vendorMobile || "",
        };
      });

      // Sort transactions by senderDateTime
      decryptedTransactions.sort((a, b) => {
        const dateA = new Date(a.sender.dateTime).getTime(); // Convert to timestamp
        const dateB = new Date(b.sender.dateTime).getTime(); // Convert to timestamp
        return dateA - dateB; // Sort in ascending order
      });

      setTotalTransactionAmount(
        decryptedTransactions.reduce((acc, curr) => {
          return acc + parseFloat(curr.sender.amount);
        }, 0)
      );

      if (decryptedTransactions.length > 0) {
        setIsEmpty(false);
        setLoader(false); // Hide the loader after fetching data
        setTransactions(decryptedTransactions);
      } else {
        setIsEmpty(true);
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error getting vendor transactions:", error);
      addToast("Error getting vendor transactions", "error");
    }
  };

  const getTransactionsByEmail = async (emailId) => {
    const q = query(
      collection(db, "BDTransactions"),
      where("vendorEmailId", "==", emailId)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  };

  const deleteVendor = async (id) => {
    try {
      console.log(`Deleting vendor with ID ${id}`);
      await deleteDoc(doc(db, "BDVendors", id));
      addToast("Vendor deleted successfully", "success");
      setScrollPositionDOM(scrollPosition);
    } catch (error) {
      console.error("Error deleting vendor:", error);
      addToast("Error deleting vendor", "error");
    }
  };

  const handleCloseTransactionPopup = () => {
    setSelectedTransaction(null); // Reset the selected transaction data after closing the popup
    setTransactionOpenPopup(false); // Close the transaction popup
    setScrollPositionDOM(scrollPosition);
  };

  return (
    <div className="vendor-payments max-w-2xl width-full p-1 bg-gray-100 rounded-lg shadow-md">
      <div className="vendor-select-container mb-4">
        <div className="p-2 mt-2 mb-2">
          {user?.role === "VENDOR" ? ( // Check if the role is VENDOR
            <h2 className="text-xl text-[#0071bd] font-bold">
              Your Transactions
            </h2>
          ) : (
            <>
              <div className="flex items-center mb-4">
                <label
                  htmlFor="vendor-select"
                  className="text-xl text-[#0071bd] font-bold mr-4"
                >
                  Select Vendor
                </label>

                <button
                  onClick={openModal} // Open modal on click
                  className="flex items-center justify-center w-10 h-10 border border-[#0071bd] bg-gray-100 text-[#0071bd] font-semibold rounded-full shadow-md  
        transition duration-100 ease-in-out transform hover:scale-105 hover:bg-[#0071bd] hover:text-white focus:outline-none"
                >
                  <UserPlus
                    size={16}
                    className="transition duration-100 ease-in-out"
                    style={{ color: "inherit" }}
                  />
                </button>
              </div>
              <select
                id="vendor-select"
                className="vendor-select border border-gray-300 rounded-md p-2"
                value={selectedVendor}
                onChange={(e) => handleVendorSelect(e.target.value)}
              >
                <option value="">Select a vendor</option>
                {vendorsList.map((vendor) => (
                  <option key={vendor.id} value={vendor.vendorEmail}>
                    {vendor.vendorName}
                  </option>
                ))}
              </select>
            </>
          )}
          {/* Do Transaction Button */}

          <div className="flex space-x-2 mt-4">
            {/* Payment Button */}
            <button
              onClick={() => setTransactionOpenPopup(true)}
              className="animated-gradient-button flex-1 relative flex items-center border border-orange-400 bg-gradient-to-r from-orange-500 to-yellow-500 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out hover:scale-105 focus:outline-none overflow-hidden min-w-[120px] animated-border"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8v8m4-4H8"
                />
              </svg>
              Payment
            </button>

            {/* Total Amount Button */}
            <button
              onClick={() => {}}
              className="animated-gradient-button-transaction flex-1 relative flex items-center border border-green-400 bg-gradient-to-r from-green-500 to-blue-500 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-200 ease-in-out hover:scale-105 focus:outline-none overflow-hidden min-w-[120px] animated-border"
            >
              <WhiteSVGIcon className="h-5 w-5" />
              <span className="text-sm ml-2">
                ₹{displayedAmount.toLocaleString("en-IN")}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="transactions-container">
        {transactions?.length === 0 ? ( // Check if there are no transactions
          <div className="no-transactions-message flex items-center justify-center h-full">
            <h2 className="text-xl font-bold mt-4 text-center text-[#0071bd]">
              {isEmpty
                ? "No Transactions Found For This Vendor"
                : "Please Select Vendor To See Transactions"}
            </h2>
          </div>
        ) : (
          transactions.map((transaction) => (
            <TransactionCard
              key={transaction?.id ?? ""}
              transaction={transaction}
              onEdit={handleEditTransaction}
              onDelete={handleDeleteTransaction}
            />
          ))
        )}
      </div>

      {/* Add Vendor Popup Modal */}
      {isModalOpen && (
        <AddVendorPopup
          onClose={closeModal}
          existingVendor={null} // Pass null since we are adding a new vendor
          saveVendor={saveVendor}
          deleteVendor={deleteVendor} // You can decide whether to use this in add mode
          setLoader={setLoader}
          addToast={addToast}
        />
      )}
      {transactionOpenPopup && (
        <AddOrEditTransaction
          vendors={vendorsList}
          transaction={selectedTransaction} // Pass the transaction data for editing
          isOpen={transactionOpenPopup} // Control modal visibility
          onClose={handleCloseTransactionPopup} // Function to close the modal
          onSave={handleSaveTransaction} // Function to save the transaction
          onDelete={handleDeleteTransaction} // Function to delete the transaction
        />
      )}
      {/* <ScrollToTop /> */}
    </div>
  );
};

export default VendorPayments;
