// import React, { useState } from 'react';
// import ProfileSheet from './ProfileSheet';
// import SheetTabs from './SheetTabs';
// import { format } from 'date-fns';
// import { v4 as uuidv4 } from 'uuid';
// import {toast, useToast } from '../../components/ui/use-toast';
// import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
// import { Calendar } from "../../components/ui/calendar";

// interface Profile {
//   id: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   mobile: string;
//   yearPassed: string;
//   branch: string;
//   graduation: string;
//   resume: string;
// }

// interface Sheet {
//   name: string;
//   profiles: Profile[];
// }

// const ProfilePage = ({setLoader,addToast,user}) => {
//   const [sheets, setSheets] = useState<Sheet[]>([
//     {
//       name: `${format(new Date(), 'yyyy-MM-dd')}_Profiles`,
//       profiles: [],
//     },
//   ]);
//   const [activeSheet, setActiveSheet] = useState(sheets[0].name);
//   const [showDatePicker, setShowDatePicker] = useState(false);

//   const handleAddSheet = () => {
//     const today = format(new Date(), 'yyyy-MM-dd');
//     const todaySheetName = `${today}_Profiles`;

//     if (sheets.some(sheet => sheet.name === todaySheetName)) {
//       setShowDatePicker(true);
//       toast({
//         title: "Sheet already exists",
//         description: "Please select a future date for the new sheet.",
//         variant: "destructive",
//       });
//       return;
//     }

//     const newSheet = {
//       name: todaySheetName,
//       profiles: [],
//     };

//     setSheets([...sheets, newSheet]);
//     setActiveSheet(newSheet.name);
//     toast({
//       title: "New sheet created",
//       description: `Created sheet: ${newSheet.name}`,
//     });
//   };

//   const handleAddProfile = () => {
//     setSheets(sheets.map(sheet => {
//       if (sheet.name === activeSheet) {
//         return {
//           ...sheet,
//           profiles: [...sheet.profiles, {
//             id: uuidv4(),
//             firstName: '',
//             lastName: '',
//             email: '',
//             mobile: '',
//             yearPassed: '',
//             branch: '',
//             graduation: '',
//             resume: '',
//           }],
//         };
//       }
//       return sheet;
//     }));
//   };

//   const handleUpdateProfile = (id: string, field: keyof Profile, value: string) => {
//     setSheets(sheets.map(sheet => {
//       if (sheet.name === activeSheet) {
//         return {
//           ...sheet,
//           profiles: sheet.profiles.map(profile => {
//             if (profile.id === id) {
//               return { ...profile, [field]: value };
//             }
//             return profile;
//           }),
//         };
//       }
//       return sheet;
//     }));
//   };

//   const handleDeleteProfile = (id: string) => {
//     setSheets(sheets.map(sheet => {
//       if (sheet.name === activeSheet) {
//         return {
//           ...sheet,
//           profiles: sheet.profiles.filter(profile => profile.id !== id),
//         };
//       }
//       return sheet;
//     }));
//     toast({
//       title: "Profile deleted",
//       description: "The profile has been removed from the sheet.",
//     });
//   };

//   const handleDownloadExcel = () => {
//     // In a real application, this would generate and download an Excel file
//     // with proper formatting (bold headers, borders, etc.)
//     toast({
//       title: "Excel download started",
//       description: "Your file will be downloaded shortly.",
//     });
//   };

//   const handleDateSelect = (date: Date) => {
//     const newSheetName = `${format(date, 'yyyy-MM-dd')}_Profiles`;
//     if (sheets.some(sheet => sheet.name === newSheetName)) {
//       toast({
//         title: "Sheet already exists",
//         description: "Please select a different date.",
//         variant: "destructive",
//       });
//       return;
//     }

//     const newSheet = {
//       name: newSheetName,
//       profiles: [],
//     };

//     setSheets([...sheets, newSheet]);
//     setActiveSheet(newSheet.name);
//     setShowDatePicker(false);
//     toast({
//       title: "New sheet created",
//       description: `Created sheet: ${newSheet.name}`,
//     });
//   };

//   const activeSheetData = sheets.find(sheet => sheet.name === activeSheet) || sheets[0];

//   return (
//     <div className="min-h-screen flex flex-col bg-gray-50">
//       <header className="bg-excel-primary text-white p-4">
//         <h1 className="text-2xl font-semibold">Profile Manager</h1>
//       </header>

//       <main className="flex-1 container mx-auto py-6">
//         <ProfileSheet
//           sheetName={activeSheet}
//           profiles={activeSheetData.profiles}
//           onAddProfile={handleAddProfile}
//           onUpdateProfile={handleUpdateProfile}
//           onDownloadExcel={handleDownloadExcel}
//           onDeleteProfile={handleDeleteProfile}
//         />
//       </main>

//       <footer className="mt-auto">
//         <SheetTabs
//           sheets={sheets.map(s => s.name)}
//           activeSheet={activeSheet}
//           onSheetChange={setActiveSheet}
//           onAddSheet={handleAddSheet}
//         />
//       </footer>

//       <AlertDialog open={showDatePicker} onOpenChange={setShowDatePicker}>
//         <AlertDialogContent>
//           <AlertDialogHeader>
//             <AlertDialogTitle>Select Date for New Sheet</AlertDialogTitle>
//             <AlertDialogDescription>
//               Please select a future date for the new sheet.
//             </AlertDialogDescription>
//           </AlertDialogHeader>
//           <div className="py-4">
//             <Calendar
//               mode="single"
//               selected={new Date()}
//               onSelect={(date) => date && handleDateSelect(date)}
//               disabled={(date) => date < new Date()}
//             />
//           </div>
//           <AlertDialogFooter>
//             <AlertDialogCancel>Cancel</AlertDialogCancel>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>
//     </div>
//   );
// };

// export default ProfilePage;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProfileTable from "./ProfileTable";
import { Profile } from "../types";

const ProfilesPage = ({ setLoader, addToast, user }) => {
  const { status } = useParams<{ status: string }>();
  const [profiles, setProfiles] = useState<any[]>([]);
  const [tempProfiles, setTempProfiles] = useState<any[]>([]);

  return (
    <div className="p-0">
      <h1 className="text-2xl font-bold text-[#0071bd] mb-6  bg-gray-100 p-3 rounded-md shadow-md">
        All Stored Profiles in DataBase
        <span className="bg-[#0071bd]  ml-6 text-white text-sm font-semibold px-2 py-1 rounded-full">
          {profiles.length}
        </span>
      </h1>
      <ProfileTable
        setLoader={setLoader}
        addToast={addToast}
        profiles={profiles}
        tempProfiles={tempProfiles}
        setProfiles={setProfiles}
        setTempProfiles={setTempProfiles}
        user={user}
      />
    </div>
  );
};

export default ProfilesPage;
