import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-[#0071bd] text-white text-center py-2">
            <p className="font-bold"> © 2025 All Rights Reserved GSR Sagar</p>
            <p></p>  <br />
        </footer>
    );
};

export default Footer;