// src/firebase.js  
import { initializeApp } from "firebase/app";  
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';   

const firebaseConfig = {
  apiKey: "AIzaSyB8j6kgFV51YCKAl_8Ia8rV01QvJxK0E68",
  authDomain: "bytesplash-db.firebaseapp.com",
  databaseURL: "https://bytesplash-db-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bytesplash-db",
  storageBucket: "bytesplash-db.firebasestorage.app",
  messagingSenderId: "759832240114",
  appId: "1:759832240114:web:cf02b92fd599156f86d56b",
  measurementId: "G-YW7HG9LWJ1"
};
// Initialize Firebase  
const app = initializeApp(firebaseConfig);  
const analytics = getAnalytics(app);  
const storage = getStorage(app); 
export { storage ,app};
