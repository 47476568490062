import React, { useState } from "react";
import {
  LayoutDashboard,
  BookOpen,
  Users,
  IdCard,
  FileText,
  Settings,
  Bell,
  Inbox,
  LogOut,
  Search,
  User,
  Grid,
  List,
  ArrowLeft,
  ArrowRight,
  DollarSign,
} from "lucide-react";
import "./css/Courses.css"; // Import CSS module
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";

interface Course {
  id: number;
  title: string;
  rating: number;
  reviewCount: number;
  lessonCount: number;
  studentCount: number;
  price: string;
  isFeatured?: boolean;
  imageUrl: string;
}

const CourseCard: React.FC<{ course: any }> = ({ course }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col  border-gray-100">
      <div className="relative">
        <img
          src={course.imageUrl}
          alt={course.title}
          className="w-full h-40 object-cover"
        />
        {course.isFeatured && (
          <div className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded">
            Featured
          </div>
        )}
      </div>

      <div className="p-4 flex-grow">
        <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
        <div className="flex items-center mb-2">
          <span className="text-yellow-500 mr-1">
            {Array.from({ length: 5 }, (_, i) => (
              <span
                key={i}
                className={`lucide-star ${
                  i < course.rating ? "fill-yellow-500" : ""
                }`}
              >
                ★
              </span>
            ))}
          </span>
          <span className="text-sm text-gray-500">({course.reviewCount})</span>
        </div>
        <div className="flex items-center text-gray-500 mb-2">
          <BookOpen size={14} className="mr-1" />
          <span className="text-sm">{course.lessonCount} Lesson</span>
          <Users size={14} className="ml-2 mr-1" />
          <span className="text-sm">{course.studentCount} Students</span>
        </div>
        <div className="mb-4">
          <span className="text-xl font-semibold">{course.price}</span>
          {course.price !== "Free" && (
            <span className="ml-1 text-gray-500 line-through text-sm">
              {course.price * 2.5}
            </span>
          )}
        </div>
        <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
          View Detail
        </button>
      </div>
    </div>
  );
};

const CoursesFn: React.FC<any> = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const courses: Course[] = [
    {
      id: 1,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹10,000",
      imageUrl:
        "https://images.unsplash.com/photo-1554228665-76424ff80760?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8YnVzaW5lc3MlMjBmb3JtYXQlMjBkaXNjdXNzaW9ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 2,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 506,
      price: "₹10,000",
      isFeatured: true,
      imageUrl:
        "https://images.unsplash.com/photo-1573497019295-2c736683676b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YnVzaW5lc3MlMjBmb3JtYXQlMjBkaXNjdXNzaW9ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 3,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹10,000",
      imageUrl:
        "https://images.unsplash.com/photo-1556761175-c47ac0813b21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 4,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹2,000",
      imageUrl:
        "https://images.unsplash.com/photo-1579576492782-446497815a12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 5,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹1,000",
      imageUrl:
        "https://images.unsplash.com/photo-1573801524121-932152ca5826?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 6,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹2,000",
      imageUrl:
        "https://images.unsplash.com/photo-1565708096852-8ff5d4ab3a1c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 7,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "Free",
      imageUrl:
        "https://images.unsplash.com/photo-1523580846011-071d3a05431f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzV8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 8,
      title: "How to Budget and Forecast for your business",
      rating: 4.8,
      reviewCount: 280,
      lessonCount: 8,
      studentCount: 200,
      price: "₹2,000",
      imageUrl:
        "https://images.unsplash.com/photo-1621591983842-a086a77015ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fGJ1c2luZXNzJTIwZm9ybWF0JTIwZGlzY3Vzc2lvbnxlbnwwfHwwfHx8&auto=format&fit=crop&w=500&q=60",
    },
  ];

  return (
    <div className={`app-container ${isDarkMode ? "dark-mode" : ""}`}>
      <aside className="sidebar">
        <div className="sidebar-header">
          <h2 className="logo">ByteSplash</h2>
        </div>
        <nav>
          <ul>
            <li>
              <LayoutDashboard className="icon" />
              Dashboard
            </li>
            <li>
              <BookOpen className="icon" />
              Courses
            </li>
            <li>
              <Users className="icon" />
              Students
            </li>
            <li>
              <IdCard className="icon" />
              ID Card
            </li>
            <li>
              <FileText className="icon" />
              Users
            </li>
            <li>
              <FileText className="icon" />
              Reports
            </li>
            <li>
              <Settings className="icon" />
              Settings
            </li>
            <li>
              <Bell className="icon" />
              Notification
            </li>
            <li>
              <Inbox className="icon" />
              Inbox
            </li>
            <li>
              <LogOut className="icon" />
              Logout
            </li>
          </ul>
        </nav>
        <div className="theme-toggle" onClick={toggleDarkMode}>
          {isDarkMode ? (
            <FontAwesomeIcon icon={faSun} className="icon" />
          ) : (
            <FontAwesomeIcon icon={faMoon} className="icon" />
          )}
          <span className="theme-text">{isDarkMode ? "Light" : "Dark"}</span>
        </div>
      </aside>
      <main className="main-content">
        <header className="app-header">
          <div className="summary-overview">Summary Overview:</div>
          <div className="search-box">
            <input type="text" placeholder="Search Here" />
            <Search className="search-icon" />
          </div>
          <div className="header-options">
            <select>
              <option>EN</option>
              <option>IND</option>
            </select>
            <img
              src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
              alt="Admin Avatar"
              className="admin-avatar"
            />
            <span className="admin-text">
              Admin <br />
              Super Admin
            </span>
          </div>
        </header>
        <div className="dashboard-summary">
          <div className="summary-card">
            <Users className="card-icon" />
            <div className="card-content">
              <span className="card-title">Total students</span>
              <span className="card-value">1,200</span>
            </div>
          </div>
          <div className="summary-card">
            <BookOpen className="card-icon" />
            <div className="card-content">
              <span className="card-title">Total courses</span>
              <span className="card-value">17</span>
            </div>
          </div>
          <div className="summary-card">
            <User className="card-icon" />
            <div className="card-content">
              <span className="card-title">Mentors</span>
              <span className="card-value">17</span>
            </div>
          </div>
          <div className="summary-card">
            <DollarSign className="card-icon" />
            <div className="card-content">
              <span className="card-title">Total Projects</span>
              <span className="card-value">10</span>
            </div>
          </div>
        </div>
        <section className="courses-section">
          <div className="courses-header">
            <h2 className="text-2xl font-semibold mb-4">All courses</h2>
            <div className="flex items-center">
              <select className="border rounded px-2 py-1 mr-2">
                <option>Newly published</option>
              </select>
              <div className="flex gap-2">
                <Grid className="w-5 h-5 cursor-pointer" />
                <List className="w-5 h-5 cursor-pointer" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {courses.map((course) => (
              <CourseCard key={course.id} course={course} />
            ))}
          </div>
          <div className="table-pagination">
            <span className="showing-text">Showing 1-10</span>
            <div className="pagination-controls">
              <button>
                <ArrowLeft />
              </button>
              <button className="active">1</button>
              <button>2</button>
              <button>3</button>
              <button>4</button>
              <button>
                <ArrowRight />
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CoursesFn;
