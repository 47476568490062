// eventsSlice.ts  
import { createSlice, PayloadAction } from '@reduxjs/toolkit';  
import moment from "moment";   
//@ts-ignore  
import { Calendar, momentLocalizer } from "react-big-calendar";  

// Define the initial state type  
interface Event {  
  id: string;  
  email: string;  
  date: string; // or Date, depending on how you want to handle it  
  // add additional fields as necessary  
}  

interface Resource {  
  id: string;        // Unique identifier for the resource  
  name: string;      // Name of the resource  
  email: string;     // Associated email (if applicable)  
  // Add additional fields as necessary  
}  

interface EventsState {  
  events: any[];  
  filteredEvents: Event[];  
  selectedEmail: string | null;  
  selectedDate: any | null; // or Date  
  resources: any[]; // Define resources as an array of Resource  
}  

// Define the initial state  
const initialState: EventsState = {  
  events: [],  
  filteredEvents: [],  
  selectedEmail: null,  
  selectedDate: null,  
  resources: [] // Initialize resources as an empty array   
};  

// Create the slice  
const eventsSlice = createSlice({  
  name: 'events',  
  initialState,  
  reducers: {  
    // Add event(s)  
    addEvent: (state, action: PayloadAction<Event>) => {  
      state.events.push(action.payload);  
      state.filteredEvents.push(action.payload); // Keep filteredEvents in sync  
    },  
    
    // Update an event by id  
    updateEvent: (state, action: PayloadAction<Event>) => {  
      const index = state.events.findIndex(event => event.id === action.payload.id);  
      if (index !== -1) {  
        state.events[index] = action.payload;  
        // Optionally update filteredEvents if necessary  
        const filteredIndex = state.filteredEvents.findIndex(event => event.id === action.payload.id);  
        if (filteredIndex !== -1) {  
          state.filteredEvents[filteredIndex] = action.payload;  
        }  
      }  
    },  
    
    // Delete an event by id  
    deleteEvent: (state, action: PayloadAction<string>) => {  
      const id = action.payload;  
      state.events = state.events.filter(event => event.id !== id);  
      state.filteredEvents = state.filteredEvents.filter(event => event.id !== id); // Keep filteredEvents in sync  
    },  

    // Set selected email  
    setSelectedEmailValue: (state, action: PayloadAction<string | null>) => {  
      state.selectedEmail = action.payload;  
    },  

    // Set selected date  
    setSelectedDateValue: (state, action: PayloadAction<string | null>) => {  
      state.selectedDate = action.payload;  
    },  

    // Add a resource  
    addResource: (state, action: PayloadAction<Resource>) => {  
      state.resources.push(action.payload);  
    },  

    // Update a resource by id  
    updateResource: (state, action: PayloadAction<Resource>) => {  
      const index = state.resources.findIndex(resource => resource.id === action.payload.id);  
      if (index !== -1) {  
        state.resources[index] = action.payload;  
      }  
    },  

    // Delete a resource by id  
    deleteResource: (state, action: PayloadAction<string>) => {  
      state.resources = state.resources.filter(resource => resource.id !== action.payload);  
    },  

    // Set the list of resources  
    setResourcesValue: (state, action: PayloadAction<Resource[]>) => {  
      state.resources = action.payload;  
    },  

    // Set the list of events  
    setEventsValue: (state:any, action: PayloadAction<Event[]>) => {  
      state.events = action.payload;  
    },  

    setFilteredEventsValue: (state, action: PayloadAction<Event[]>) => {  
      state.filteredEvents = action.payload;  
    },

    filterEventsValue: (state) => {  
      const { selectedEmail, selectedDate } = state;  
      
      state.filteredEvents = state.events.filter((event) => {  
        const matchesEmail = selectedEmail ? event.email === selectedEmail : true;  
        
        // Check if event.date is a valid date  
        const eventDate = event?.date && event.date?.seconds ? new Date(event?.date?.seconds * 1000) : new Date(event?.date);  
        const matchesDate = selectedDate   
          ? eventDate && moment(eventDate).isSame(selectedDate, "day")   
          : true;  

        return matchesEmail && matchesDate;  
      });  
    },  
  },  
});  

// Export the actions and reducer  
export const {  
  addEvent,  
  updateEvent,  
  deleteEvent,  
  setSelectedEmailValue,  
  setSelectedDateValue,  
  addResource,  
  updateResource,  
  deleteResource,  
  setResourcesValue, // Export the setResources action  
  setEventsValue, // Export the setEvents action  
  setFilteredEventsValue,
  filterEventsValue,  
} = eventsSlice.actions;  

export default eventsSlice.reducer;