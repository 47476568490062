import React from 'react';

interface TechnologyBadgeProps {
  technology: string;
}

const TechnologyBadge: React.FC<TechnologyBadgeProps> = ({ technology }) => {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-[#0071bd] bg-opacity-10 text-[#0071bd]">
      {technology}
    </span>
  );
};

export default TechnologyBadge;