import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Users,
  Calendar,
  ChevronDown,
  ChevronRight,
  X,
  IndianRupee,
} from "lucide-react"; // Import Rupee icon

interface SidebarProps {
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  userRole?: string; // Added prop to accept user role
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  isMobile,
  onClose,
  userRole,
}) => {
  const [expandedItem, setExpandedItem] = useState<string | null>("Schedules");

  const handleItemClick = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
      )}
      <aside
        className={`fixed left-0 top-0 h-full bg-white mt-16 overflow-y-auto shadow-lg z-50 transition-all duration-300   
          ${isMobile ? "w-[280px]" : "w-64 top-16"}`}
      >
        {isMobile && (
          <div className="h-14 bg-[#0071bd] flex items-center justify-between px-4">
            <span className="text-white font-semibold">Menu</span>
            <button
              onClick={onClose}
              className="text-white p-2 hover:bg-[#0062a3] rounded-lg"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        )}
        <nav className="p-4">
          <div>
            {/* Candidates Section - only visible if not TEMPORARY or VENDOR role */}
            {userRole !== "TEMPORARY" &&
              userRole !== "VENDOR" &&
              userRole !== "CANDIDATE" && (
                <button
                  onClick={() =>
                    setExpandedItem(
                      expandedItem === "Candidates" ? null : "Candidates"
                    )
                  }
                  className="flex items-center w-full p-3 text-gray-700 hover:bg-gray-100 rounded-lg"
                >
                  <Users className="w-5 h-5 mr-3" />
                  <span className="flex-1 text-left">Candidates</span>
                  {expandedItem === "Candidates" ? (
                    <ChevronDown className="w-4 h-4" />
                  ) : (
                    <ChevronRight className="w-4 h-4" />
                  )}
                </button>
              )}
            {expandedItem === "Candidates" && userRole !== "TEMPORARY" && (
              <div className="ml-8 mt-2 space-y-2">
                {["All", "Selected", "Offered"].map((label) => (
                  <NavLink
                    key={label}
                    to={`/candidates/${label.toLowerCase()}`}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    {label}
                  </NavLink>
                ))}
                {/* Conditionally render the 'Paid' link for SUPERADMIN role */}
                {userRole === "SUPERADMIN" && (
                  <NavLink
                    key="Paid"
                    to={`/candidates/paid`}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    Paid
                  </NavLink>
                )}
                {/* Add the Onboarded link for ADMIN role */}
                {userRole === "ADMIN" ||
                  (userRole == "ACCENTURETEAM" && (
                    <NavLink
                      key="Onboarded"
                      to={`/candidates/onboarded`}
                      onClick={handleItemClick}
                      className={({ isActive }) =>
                        `block p-2 rounded-lg ${
                          isActive
                            ? "bg-blue-50 text-blue-600"
                            : "text-gray-600 hover:bg-gray-100"
                        }`
                      }
                    >
                      Onboarded
                    </NavLink>
                  ))}
              </div>
            )}
          </div>

          {/* Schedules Section */}
          {userRole !== "VENDOR" && (
            <button
              onClick={() =>
                setExpandedItem(
                  expandedItem === "Schedules" ? null : "Schedules"
                )
              }
              className="flex items-center w-full p-3 mt-4 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              <Calendar className="w-5 h-5 mr-3" />
              <span className="flex-1 text-left">Schedules</span>
              {expandedItem === "Schedules" ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRight className="w-4 h-4" />
              )}
            </button>
          )}

          {expandedItem === "Schedules" && (
            <div className="ml-8 mt-2 space-y-2">
              {/* Only show 'Scheduled' if TEMPORARY role, otherwise show both options */}
              {(userRole === "TEMPORARY"
                ? ["Scheduled"]
                : userRole === "VENDOR"
                ? []
                : ["Scheduled", "Completed"]
              ).map((label) => (
                <NavLink
                  key={label}
                  to={`/schedules/${label.toLowerCase()}`}
                  onClick={handleItemClick}
                  className={({ isActive }) =>
                    `block p-2 rounded-lg ${
                      isActive
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-100"
                    }`
                  }
                >
                  {label}
                </NavLink>
              ))}
            </div>
          )}

          {/* Payments Section - only visible for SUPERADMIN and VENDOR roles */}
          {(userRole === "SUPERADMIN" || userRole === "VENDOR") && (
            <div>
              <button
                onClick={() =>
                  setExpandedItem(
                    expandedItem === "Payments" ? null : "Payments"
                  )
                }
                className="flex items-center w-full p-3 mt-4 text-gray-700 hover:bg-gray-100 rounded-lg"
              >
                <IndianRupee className="w-5 h-5 mr-3" />{" "}
                {/* Indian Rupee icon */}
                <span className="flex-1 text-left">Payments</span>
                {expandedItem === "Payments" ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
              </button>
              {expandedItem === "Payments" && userRole === "VENDOR" && (
                <div className="ml-8 mt-2 space-y-2">
                  <NavLink
                    key="Vendors"
                    to={`/payments/vendors`}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    Vendors
                  </NavLink>
                </div>
              )}
              {expandedItem === "Payments" && userRole === "SUPERADMIN" && (
                <div className="ml-8 mt-2 space-y-2">
                  {["Vendors", "Accenture"].map((label) => (
                    <NavLink
                      key={label}
                      to={`/payments/${label.toLowerCase()}`}
                      onClick={handleItemClick}
                      className={({ isActive }) =>
                        `block p-2 rounded-lg ${
                          isActive
                            ? "bg-blue-50 text-blue-600"
                            : "text-gray-600 hover:bg-gray-100"
                        }`
                      }
                    >
                      {label}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          )}
          <div>
            {(userRole === "ADMIN" || userRole === "SUPERADMIN") && (
              <button
                onClick={() =>
                  setExpandedItem(
                    expandedItem === "Profiles" ? null : "Profiles"
                  )
                }
                className="flex items-center w-full p-3 mt-4 text-gray-700 hover:bg-gray-100 rounded-lg"
              >
                <Users className="w-5 h-5 mr-3" />
                <span className="flex-1 text-left">Profiles</span>
                {expandedItem === "Payments" ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
              </button>
            )}

            {expandedItem === "Profiles" && userRole === "VENDOR" && (
              <div className="ml-8 mt-2 space-y-2">
                <NavLink
                  key="Vendors"
                  to={`/payments/vendors`}
                  onClick={handleItemClick}
                  className={({ isActive }) =>
                    `block p-2 rounded-lg ${
                      isActive
                        ? "bg-blue-50 text-blue-600"
                        : "text-gray-600 hover:bg-gray-100"
                    }`
                  }
                >
                  Vendors
                </NavLink>
              </div>
            )}
            {expandedItem === "Profiles" &&
              (userRole === "ADMIN" || userRole === "SUPERADMIN") && (
                <div className="ml-8 mt-2 space-y-2">
                  <NavLink
                    key={"All Stored Freshers"}
                    to={"/profiles/storedfreshers"}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    {"All Stored Freshers"}
                  </NavLink>
                  <NavLink
                    key={"All Stored Experience"}
                    to={"/profiles/storedexperience"}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    {"All Stored Experience"}
                  </NavLink>
                  <NavLink
                    key={"Scheduled Profiles"}
                    to={"/profiles/scheduledprofiles"}
                    onClick={handleItemClick}
                    className={({ isActive }) =>
                      `block p-2 rounded-lg ${
                        isActive
                          ? "bg-blue-50 text-blue-600"
                          : "text-gray-600 hover:bg-gray-100"
                      }`
                    }
                  >
                    {"Scheduled Profiles"}
                  </NavLink>
                  {userRole == "SUPERADMIN" && (
                    <NavLink
                      key={"Co-Efficients"}
                      to={"/profiles/coefficients"}
                      onClick={handleItemClick}
                      className={({ isActive }) =>
                        `block p-2 rounded-lg ${
                          isActive
                            ? "bg-blue-50 text-blue-600"
                            : "text-gray-600 hover:bg-gray-100"
                        }`
                      }
                    >
                      {"Co-Efficients"}
                    </NavLink>
                  )}
                </div>
              )}
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
