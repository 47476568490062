import React from 'react';
import { ExcelIcon, GreenDeleteIcon } from './Icons';
import * as XLSX from "xlsx-js-style";


const CandidateSearchExport = ({user,filter,isSuperAdmin, isAccentureTeam, filteredCandidates}) => {
    const EXCEL_EXTENSION = ".xlsx";
    const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    /**
  * Exports the given JSON data as an Excel file.
  * @param {Array} json - The data to export.
  * @param {string} excelFileName - The name of the Excel file.
  */
    const exportAsExcelFile = (json, excelFileName) => {
        json = JSON.parse(JSON.stringify(json));

        // Define the keys you want to include in the export  
        const keys = [

            "contact",
            "createdBy",
            "createdRole",
            "currentCTC",
            "emailId",
            "experience",
            "fullName",
            "handledBy",
            "interviewProgress",
            "isOffered",
            "isPayed",
            "isSelected",
            "technology",
            "vendorName"
        ];

        if (user?.role === "ACCENTURETEAM") {
            keys.push(
                "candidateEmailPassword",
                "cid",
                "currentCompanyName",
                "currentLocation",
                "firstRoundPanelName",
                "noticePeriod",
                "prefferedLocation",
                "previousCompanyName",
                "referenceName",
                "relevantExperience",
                "secondRoundPanelName"
            );
        }


        if (!isSuperAdmin) {
            json = json.filter((candidate) =>
                candidate.createdBy === user?.emailId || candidate.createdRole === user?.role
            );

            // Eliminate unwanted properties  
            json = json.map((candidate) => {
                const { paidAmount, balance, url, resume, ...filtered } = candidate;
                return filtered;
            });
        } else {
            json = json.map((candidate) => {
                const { url, resume, ...filtered } = candidate;
                return filtered;
            });
        }

        // Filter candidates based on the same logic as in filterCandidates  
        // json = json.filter((candidate) => {
        //     if (filter === "selected") {
        //         return (
        //             (candidate.isSelected || !candidate.isSelected) &&
        //             !candidate.isOffered &&
        //             !candidate.isPayed
        //         );
        //     } else if (filter === "offered") {
        //         return (
        //             candidate.isSelected && candidate.isOffered && !candidate.isPayed
        //         );
        //     } else if (filter === "payed") {
        //         return candidate.isSelected && candidate.isOffered && candidate.isPayed;
        //     }
        //     return false;
        // });

        // Create a new array with only the keys we want in the order we want  
        const formattedJson = json.map(candidate => {
            return keys.reduce((obj, key) => {
                obj[key] = candidate[key] || ''; // Assign empty string if key doesn't exist  
                return obj;
            }, {});
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedJson, { header: keys });

        // Apply styling to the header row  
        for (let i in worksheet) {
            if (Number(i.toString()?.split("")[1]) === 1) {
                if (typeof worksheet[i] !== "object") continue;
                worksheet[i].s = {
                    font: {
                        name: "Arial",
                        bold: true,
                        color: { rgb: "0071DB" },
                    },
                    alignment: {
                        vertical: "center",
                        horizontal: "center",
                        wrapText: true,
                    },
                };
            }
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
        XLSX.writeFile(
            wb,
            `${excelFileName}_export_${new Date().getTime()}_${filter}${EXCEL_EXTENSION}`
        );
    };

    return (
        <div className="row">
            <div className="flex-box-sidebyside flex items-center">
                <button
                     disabled={filteredCandidates.length === 0}  
                    style={{
                        verticalAlign: 'middle',
                        padding: '0.5rem 1rem',
                        border: '1px solid green',
                        backgroundColor: 'rgba(0, 128, 0, 0.1)', // 10% green background  
                        borderRadius: '6px' // Set border radius to 6px  
                    }}
                     onClick={() => exportAsExcelFile(filteredCandidates, "candidatesList")}  
                    className="ml-2 border-2 border-green-500 flex items-center transition-transform transform hover:scale-105 active:scale-95" // Rounded borders  
                >
                    <ExcelIcon className="h-6 w-6" /> {/* Adjust size as needed */}
                    <span className="mx-2" /> {/* Less gap between icons */}
                    <GreenDeleteIcon className="h-6 w-6" /> {/* Adjust size as needed */}
                </button>
            </div>
        </div>
    );
};

export default CandidateSearchExport;