import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Candidate } from '../types/candidate';

interface CandidatesState {
  candidates: Candidate[];
  selectedCandidate : any,
  loading: boolean;
  error: string | null;
  selectedEmail: string | null;
}

const initialState: CandidatesState = {
  candidates: [],
  loading: false,
  error: null,
  selectedCandidate: null,
  selectedEmail:null
};

const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    setCandidates: (state, action: PayloadAction<Candidate[]>) => {
      state.candidates = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedCandidate: (state, action: PayloadAction<any>) => {
      state.selectedCandidate = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setSelectedEmail:(state,action:PayloadAction<string | null>) => {
      state.selectedEmail = action.payload;
    },
  },
});



export const { setCandidates, setSelectedCandidate, setLoading, setError, setSelectedEmail } = candidatesSlice.actions;
export default candidatesSlice.reducer;