import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { app } from "../firebase";

const AddVendorPopup = ({
  onClose,
  existingVendor,
  deleteVendor,
  setLoader,
  addToast,
}) => {
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [role, setRole] = useState("VENDOR");
  const [vendorMobile, setVendorMobile] = useState("");
  const db = getFirestore(app);
  const auth = getAuth(app);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (existingVendor) {
      setVendorName(existingVendor.name);
      setVendorEmail(existingVendor.email);
      setRole(existingVendor.role);
    }
    scrollToTop();
  }, [existingVendor]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const vendorData = {
      vendorName: vendorName,
      vendorEmail: vendorEmail,
      role: role,
      vendorMobile: vendorMobile,
    };

    if (existingVendor) {
      saveVendor(existingVendor.id, vendorData);
    } else {
      if (
        vendorData?.vendorName &&
        vendorData?.vendorEmail &&
        vendorData?.role
      ) {
        saveVendor(null, vendorData);
      } else {
        addToast("Please fill all fields", "warning");
      }
    }
  };

  const handleDelete = () => {
    if (existingVendor) {
      deleteVendor(existingVendor.id);
      onClose();
    }
  };

  const saveVendor = async (id, data) => {
    try {
      if (!id) {
        const vendorDataRef = doc(collection(db, "BDVendors"));
        const vendorDataWithId = {
          id: vendorDataRef.id,
          ...data,
        };
        await setDoc(vendorDataRef, vendorDataWithId);
        addToast("Vendor added successfully", "success");
      } else {
        const vendorDataRef = doc(collection(db, "BDVendors"), id);
        await updateDoc(vendorDataRef, data);
        addToast("Vendor updated successfully", "success");
      }
      setLoader(false);
      onClose();
    } catch (error) {
      addToast("Error saving vendor", "error");
      setLoader(false);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start justify-center z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black/40 backdrop-blur-md transition-opacity duration-300"
        onClick={onClose}
      />

      {/* Popup Content */}
      <div
        className="relative z-50 bg-white rounded-lg shadow-2xl w-full max-w-md mx-4 transform transition-all duration-300 ease-out scale-100"
        style={{ top: "4rem" }}
      >
        <div className="p-6 sm:p-8">
          <h2 className="text-2xl text-[#0071bd] font-bold text-center mb-6">
            {existingVendor ? "Edit Vendor" : "Add Vendor"}
          </h2>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Vendor Name
              </label>
              <input
                type="text"
                placeholder="Enter Vendor Name"
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd]/20 focus:border-[#0071bd] transition-colors duration-200"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Vendor Email
              </label>
              <input
                type="email"
                placeholder="Enter Vendor Email"
                value={vendorEmail}
                onChange={(e) => setVendorEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd]/20 focus:border-[#0071bd] transition-colors duration-200"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Vendor Mobile
              </label>
              <input
                type="text"
                placeholder="Enter Vendor Mobile"
                value={vendorMobile}
                onChange={(e) => setVendorMobile(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd]/20 focus:border-[#0071bd] transition-colors duration-200"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-1">
                Role
              </label>
              <select
                value={role}
                readOnly={true}
                onChange={(e) => setRole(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd]/20 focus:border-[#0071bd] transition-colors duration-200"
                required
              >
                <option value="VENDOR">VENDOR</option>
                <option value="ADMIN">ADMIN</option>
                <option value="USER">TEMPORARY</option>
              </select>
            </div>

            <div className="flex items-center justify-between gap-4 mt-8">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200"
              >
                Cancel
              </button>

              {existingVendor && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
                >
                  Delete
                </button>
              )}

              <button
                type="submit"
                className="flex-1 px-4 py-2 bg-[#0071bd] text-white rounded-lg hover:bg-[#005a96] transition-colors duration-200"
              >
                {existingVendor ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVendorPopup;
