import React, { useEffect } from 'react';  

const Toaster = ({ message, type, onClose }) => {  
  useEffect(() => {  
    const timer = setTimeout(() => {  
      onClose();  
    }, 3000);  

    return () => clearTimeout(timer); // Cleanup timer on unmount  
  }, [onClose]);  

  const getIcon = () => {  
    switch (type) {  
      case 'success':  
        return (  
          <svg  
            xmlns="http://www.w3.org/2000/svg"  
            className="w-8 h-8 text-green-500" // Increased size  
            fill="none"  
            viewBox="0 0 24 24"  
            stroke="currentColor"  
          >  
            <circle cx="12" cy="12" r="10" stroke="green" strokeWidth="2" />  
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4" />  
          </svg>  
        );  
      case 'warning':  
        return (  
          <svg  
            xmlns="http://www.w3.org/2000/svg"  
            className="w-8 h-8 text-yellow-500" // Increased size  
            fill="none"  
            viewBox="0 0 24 24"  
            stroke="currentColor"  
          >  
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2L2 22h20L12 2z" />  
          </svg>  
        );  
      case 'error':  
        return (  
          <svg  
            xmlns="http://www.w3.org/2000/svg"  
            className="w-8 h-8 text-red-500" // Increased size  
            fill="none"  
            viewBox="0 0 24 24"  
            stroke="currentColor"  
          >  
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />  
          </svg>  
        );  
      default:  
        return null;  
    }  
  };  

  return (  
    <div  
      className={`fixed top-4 right-4 p-4 bg-white shadow-lg rounded-lg flex items-center space-x-2 border-l-4 z-50 ${type === 'success' ? 'border-green-500' : type === 'warning' ? 'border-yellow-500' : 'border-red-500'}`}  
      style={{ zIndex: 9999 }} // Ensure highest z-index  
    >  
      {getIcon()}  
      <div className="flex-1">  
        <p className="text-lg font-semibold">{message}</p> {/* Increased font size */}  
      </div>  
      <button onClick={onClose} className="text-gray-600 hover:text-gray-800">  
        <svg  
          xmlns="http://www.w3.org/2000/svg"  
          className="w-6 h-6" // Increased size  
          fill="none"  
          viewBox="0 0 24 24"  
          stroke="currentColor"  
        >  
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />  
        </svg>  
      </button>  
    </div>  
  );  
};  

export default Toaster;