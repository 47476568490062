import React from 'react';  

const ConfirmationPopup = ({ message, name, onConfirm, onCancel }) => {  
  return (  
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">  
      <div className="bg-white rounded-lg shadow-lg p-4 md:p-6 w-11/12 md:w-96"> {/* Set width to 80% on mobile */}  
        <h2 className="text-lg font-bold text-[#014077FF] mb-2">Confirmation</h2> {/* Darker color for header */}  
        <p className="mb-4">{message}</p>  
        <p className="font-medium">{name}</p>  
        <div className="flex justify-end space-x-4 mt-4"> {/* Reduced margin-top */}  
          <button  
            onClick={onCancel}  
            className="px-4 py-2 text-white bg-[#044876FF] rounded hover:bg-blue-600 transition duration-200"  
          >  
            Cancel  
          </button>  
          <button  
            onClick={onConfirm}  
            className="px-4 py-2 text-white bg-red-700 rounded hover:bg-red-600 transition duration-200"  
          >  
            Delete  
          </button>  
        </div>  
      </div>  
    </div>  
  );  
};  

export default ConfirmationPopup;