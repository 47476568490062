import React from 'react';  
import { Phone } from 'lucide-react'; // Importing Phone icon from lucide-react  

const AccenturePayments = () => {  
  // Sample data for sender and receiver  
  const sender = {  
    name: "John Doe",  
    mobile: "+91 98765 43210",  
    amount: "₹5000",  
  };  

  const receiver = {  
    name: "Jane Smith",  
    mobile: "+91 99876 54321",  
  };  

  return (  
    <div className="flex items-center justify-center mt-0 min-h-screen bg-gray-200">  
    <div className="rounded-md p-4 text-center">  
        <h1 className="text-3xl font-bold text-[#0071bd] mb-4">  
            In Progress  
        </h1>  
        <p className="text-gray-500 font-medium mb-0">  
            This page is currently under development. Please check back later for updates.  
        </p>  
    </div>  
</div>   
  );  
};  

export default AccenturePayments;