import { applyMiddleware, createStore , configureStore , combineReducers} from '@reduxjs/toolkit';  
import authReducer from './authSlice';  
import candidatesReducer from './candidatesSlice';  
import eventsReducer from './eventsSlice';  
import {thunk} from 'redux-thunk';  

// Combine all reducers into a single root reducer  
const rootReducer = combineReducers({  
  auth: authReducer,  
  candidates: candidatesReducer,  
  events: eventsReducer,  
}); 

export const store = createStore(  
  rootReducer,  
  applyMiddleware(thunk) // Apply thunk middleware here  
);  



// export type RootState = ReturnType<typeof store.getState>;  
// export type AppDispatch = typeof store.dispatch; 