export const formatAmount = (value) => {  
    if (value === null || value === undefined) {  
      return "";  
    }  
    // Convert the number to a string  
    const numberString = value.toString();  

    // Split the number on the decimal point  
    const parts = numberString.split(".");  
    let integerPart = parts[0];  
    const decimalPart = parts[1] ? "." + parts[1] : "";  

    // Indian Number Formatting  
    const lastThreeDigits = integerPart.substring(integerPart.length - 3);  
    const otherDigits = integerPart.substring(0, integerPart.length - 3);  

    // If there are digits before the last three  
    if (otherDigits !== "") {  
      integerPart =  
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +  
        "," +  
        lastThreeDigits;  
    }  
    if(value?.length==3) return value;
    return integerPart + decimalPart;  
  };  

  export const formatAmount1 = (amount) => {
    const num = parseFloat(amount);
    return num.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  };