import React, { useEffect, useState } from 'react';  

const ScrollToTop: React.FC = () => {  
  const [isVisible, setIsVisible] = useState(false);  

  const handleScroll = () => {  
    if (window.scrollY > 300) {  
      setIsVisible(true);  
    } else {  
      setIsVisible(false);  
    }  
  };  

  const scrollToTop = () => {  
    window.scrollTo({  
      top: 0,  
      behavior: 'smooth',  
    });  
  };  

  useEffect(() => {  
    window.addEventListener('scroll', handleScroll);  
    return () => {  
      window.removeEventListener('scroll', handleScroll);  
    };  
  }, []);  

  return (  
    <div  
      className={`fixed bottom-4 right-4 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}  
      style={{ zIndex: 1000 }}  
    >  
      <button  
        onClick={scrollToTop}  
        className="bg-[#0056a0] text-white rounded-full p-2 shadow-lg hover:bg-blue-600 transition duration-200" // Darker shade of #0071bd  
        aria-label="Scroll to top"  
      >  
        <svg  
          xmlns="http://www.w3.org/2000/svg"  
          className="w-6 h-6"  
          fill="none"  
          viewBox="0 0 24 24"  
          stroke="currentColor"  
        >  
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4l-8 8h16l-8-8z" /> {/* Upward arrow path */}  
        </svg>  
      </button>  
    </div>  
  );  
};  

export default ScrollToTop;



const UpwardArrowIcon = () => (  
    <svg  
      fill="#0071bd"  
      viewBox="0 0 512 512"  
      style={{ width: '28px', height: '28px' }}  
      xmlns="http://www.w3.org/2000/svg"  
      xmlnsXlink="http://www.w3.org/1999/xlink"  
      stroke="#0071bd"  
      strokeWidth="7.68"  
    >  
      <g>  
        <path d="M256,0C114.88,0,0,114.88,0,256s114.88,256,256,256s256-114.88,256-256S397.12,0,256,0z M256,490.667 c-129.387,0-234.667-105.28-234.667-234.667S126.613,21.333,256,21.333S490.667,126.613,490.667,256S385.387,490.667,256,490.667 z" />  
        <path d="M263.573,99.093c-2.027-1.92-4.693-3.093-7.467-3.093H256c-2.773,0-5.44,1.173-7.467,3.093L131.2,216.427 c-4.267,4.053-4.373,10.88-0.213,15.04c4.16,4.16,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213l99.093-99.093 v272.533c0,5.333,3.84,10.133,9.067,10.88c6.613,0.96,12.267-4.16,12.267-10.56V132.373l99.093,99.093 c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827L263.573,99.093z" />  
      </g>  
    </svg>  
  );  
  
