import React, { useState } from 'react';  
import { Phone, Mail, Edit2, Copy, IndianRupee, Trash2, FileText, User, Eye, EyeOff } from 'lucide-react';  
import { Candidate } from '../../../types/candidate';  
import StatusBadge from '../CandidateCard/StatusBadge';  
import TechnologyBadge from '../CandidateCard/TechnologyBadge';  
import ExperienceBadge from '../CandidateCard/ExperienceBadge'; 
//@ts-ignore 
import {formatAmount} from '../../utilities';  
//@ts-ignore  
import { CurrencyIcon } from '../../Icons.jsx';  

interface CandidateCardProps {  
  candidate: any;  
  onEdit: (candidate: any) => any;  
  onDelete: (candidate: any) => any;  
  isSuperAdmin: boolean;  
}  

const CandidateCard: React.FC<any> = ({  
  candidate,  
  onEdit,  
  onDelete,  
  setLoader,  
  isSuperAdmin,  
  isAccentureTeam  
}) => {  
  const [showPassword, setShowPassword] = useState(false);  
  const [copied, setCopied] = useState(false);  
  const [showMore, setShowMore] = useState(false); // State to manage the visibility of additional details  

  const downloadResumeByUrl = async (fileUrl: any) => {  
    setLoader(true);  
    if (!fileUrl) {  
      return;  
    }  
    try {  
      const response = await fetch(fileUrl);  
      if (!response.ok) throw new Error("Network response was not ok");  
      const blob = await response.blob();  
      const url = window.URL.createObjectURL(blob);  
      const link: any = document.createElement("a");  
      link.href = url;  
      link.setAttribute("download", candidate.resume); // Provide a name for the file  
      document.body.appendChild(link);  
      link.click();  
      link.parentNode.removeChild(link);  
      setLoader(false);  
    } catch (error) {  
      setLoader(false);  
    }  
  };  

  const handleCopyPassword = () => {  
    navigator.clipboard.writeText(candidate.candidateEmailPassword);  
    setCopied(true);  
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds  
  };  
 

  return (  
    <div className="hover900 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-900 transform hover:scale-105 transition-all duration-900 ease-in-out">  
      <div className="p-6">  
        <div className="flex justify-between items-start mb-4">  
          <div className="flex items-center gap-3">  
            <div className="w-12 h-12 rounded-full bg-[#0071bd] bg-opacity-10 flex items-center justify-center">  
              <User className="w-6 h-6 text-[#0071bd]" />  
            </div>  
            <div className="flex flex-col">  
              <h3 className="text-xl font-bold text-gray-900">{candidate.fullName}</h3>  
              <div className="flex items-center gap-3 mt-1">  
                <TechnologyBadge technology={candidate.technology.split(" ")[0]} />  
                <span className="text-sm text-gray-600">{candidate.experience}</span>  
              </div>  
            </div>  
          </div>  
          <div className="flex gap-2">  
            <button  
              onClick={() => onEdit(candidate)}  
              className="p-2 text-[#0071bd] hover:bg-[#0071bd] hover:bg-opacity-10 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"  
            >  
              <Edit2 className="w-5 h-5" />  
            </button>  
            <button  
              onClick={() => onDelete(candidate)}  
              className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"  
            >  
              <Trash2 className="w-5 h-5" />  
            </button>  
          </div>  
        </div>  

        <div className="space-y-3">  
          <div className="flex items-center gap-2 text-gray-600">  
            <Mail className="w-4 h-4" />  
            <span className="text-sm font-medium">{candidate.emailId}</span>  
          </div>  
          <div className="flex items-center gap-2 text-gray-600">  
            <Phone className="w-4 h-4" />  
            <span className="text-sm font-medium">{candidate.contact}</span>  
          </div>  
          {candidate.resume && (  
            <div className="flex items-center gap-2 text-[#0071bd]">  
              <FileText className="w-4 h-4" />  
              <a onClick={() => downloadResumeByUrl(candidate.url)} className="text-sm font-medium hover:underline transition-colors duration-300 ease-in-out">View Resume</a>  
            </div>  
          )}  
        </div>  

        {/* Expand/Collapse Section */}  
        <div className="mt-4 pt-4 border-t border-gray-100">  
          <span  
            onClick={() => setShowMore(!showMore)}  
            className="text-[#0071bd] font-bold cursor-pointer hover:underline"  
          >  
            {showMore ? 'Show Less' : 'Show More'}  
          </span>  

          {showMore && (  
            <div className="mt-2 text-sm text-gray-600">  
              <div className="flex flex-wrap gap-4">  
                {isAccentureTeam && ( // Show additional fields for ACCENTURETEAM role  
                  <>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">CID</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.cid || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">Current CTC</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.currentCTC || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">First Round Panel</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.firstRoundPanelName || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">Second Round Panel</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.secondRoundPanelName || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">Previous Company</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.previousCompanyName || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">Current Location</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.currentLocation || 'N/A'}</p>  
                    </div>  
                    <div>  
                      <span className="text-sm text-gray-500 font-medium">Current Company</span>  
                      <p className="mt-1 font-semibold text-gray-900">{candidate.currentCompanyName || 'N/A'}</p>  
                    </div>  
                    <div className="flex items-center gap-2">  
                      <span className="text-sm text-gray-500 font-medium">Email Password</span>  
                      <button onClick={() => setShowPassword(!showPassword)} className="text-gray-600">  
                        {showPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}  
                      </button>  
                      <div className="flex items-center gap-2">  
                        <span className="text-sm font-medium">  
                          {showPassword ? candidate.candidateEmailPassword : 'XXXXXXX'}  
                        </span>  
                        <button  
                          onClick={handleCopyPassword}  
                          className={`flex items-center p-2 rounded transition-colors duration-300 ease-in-out ${copied ? 'bg-green-500' : 'bg-gray-300 hover:bg-gray-400'}`}  
                          aria-label="Copy password"  
                        >  
                          <Copy  
                            className={`w-4 h-4 ${copied ? 'text-[#0071bd] stroke-white' : 'text-gray-800'}`}  
                            style={{ stroke: copied ? 'white' : 'currentColor' }} // Set stroke color based on copied state  
                          />  
                        </button>  
                      </div>  
                    </div>  
                  </>  
                )}  
                <div className="mt-4"> {/* Add margin-top to separate from previous content */}  
                  <div>  
                    <span className="text-sm text-gray-500 font-medium">Status</span>  
                    <div className="mt-1">  
                      <StatusBadge role={isSuperAdmin} status={candidate?.interviewProgress} />  
                    </div>  
                  </div>  
                </div>  
                {isSuperAdmin && (  
                  <>  
                    {candidate.balance && <div>  
                      <span className="text-sm text-gray-500 font-medium">Balance</span>  
                      <p className="mt-1 font-semibold text-gray-900">₹{formatAmount(candidate.balance)}</p>  
                    </div>}  
                    {candidate.paidAmount &&  
                      <div>  
                        <span className="text-sm text-gray-500 font-medium">Paid Amount</span>  
                        <p className="mt-1 font-semibold text-gray-900">₹{formatAmount(candidate.paidAmount)}</p>  
                      </div>}  
                  </>  
                )}  
                {isSuperAdmin && candidate.isPayed && ( // Display payment status if isPaid is true  
                  <div className="mt-4 flex items-center">  
                    <span className="text-sm text-gray-500 font-medium">Payment Status</span>  
                    <p className="mt-1 font-semibold text-green-600 flex items-center ml-2">  
                      <CurrencyIcon className="w-4 h-4 mr-1" />&nbsp; {/* Rupee icon */}  
                      Completed {isSuperAdmin && `₹(${formatAmount(candidate.paidAmount)})`}  
                    </p>  
                  </div>  
                )}  
              </div>  
            </div>  
          )}  
        </div>  
      </div>  
    </div>  
  );  
};  

export default CandidateCard;