import { Search } from "lucide-react";
import React from "react";
//@ts-ignore
import SchedulesExports from "./SchedulesExport";

interface SearchControlsProps {
  isMobile: boolean;
  searchTerm: string;
  selectedDate: string;
  interviews: any[];
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchControls: React.FC<any> = React.memo(({
  isMobile,
  searchTerm,
  selectedDate,
  interviews,
  onSearchChange,
  onDateChange,
}) => {
  if (isMobile) {
    return (
      <div className="mb-6 flex flex-col gap-4 md:gap-6">
        <div className="flex items-center gap-1 w-full flex-wrap">
          <div className="relative flex-1 min-w-0">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search candidates..."
              value={searchTerm}
              onChange={onSearchChange}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd] focus:border-transparent"
            />
          </div>
          <div className="flex-shrink-0">
            <SchedulesExports filteredSchedules={interviews} />
          </div>
        </div>
        <div className="flex items-center w-full">
          <input
            type="date"
            value={selectedDate}
            onChange={onDateChange}
            className="w-full md:w-1/3 rounded-md border border-gray-300 bg-gray-50 py-2 pl-4 pr-2 shadow-lg focus:ring-2 focus:ring-[#0071bd] focus:border-[#0071bd] hover:border-gray-400 transition-all duration-300 ease-in-out text-gray-700"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6 flex gap-4 items-center w-full flex-wrap">
      <div className="relative flex-1 min-w-0">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search candidates..."
          value={searchTerm}
          onChange={onSearchChange}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#0071bd] focus:border-transparent"
        />
      </div>
      <div className="flex-shrink-0 w-full md:w-auto">
        <input
          type="date"
          value={selectedDate}
          onChange={onDateChange}
          className="w-full md:w-auto rounded-md border border-gray-300 bg-gray-50 py-2 pl-4 pr-2 shadow-lg focus:ring-2 focus:ring-[#0071bd] focus:border-[#0071bd] hover:border-gray-400 transition-all duration-300 ease-in-out text-gray-700"
        />
      </div>
      <div className="flex-shrink-0 w-full md:w-auto">
        <SchedulesExports filteredSchedules={interviews} />
      </div>
    </div>
  );
});

SearchControls.displayName = "SearchControls";