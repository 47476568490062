// @ts-nocheck  
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../store/store';
import Footer from './Footer';
import Header from './Header';
import MobileHeader from './MobileHeader';
import ScrollToTop from './ScrollToTop';
import Sidebar from './Sidebar';

import { useLocation } from 'react-router-dom';

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {user,isAuthenticated}   = useSelector((state: RootState) => state.auth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      // if (!mobile && !isSidebarOpen) {
      //   setIsSidebarOpen(true);
      // }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSidebarOpen]);

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {<RouteTracker />}
     
      {isMobile ? (
        <MobileHeader toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
      ) : (
        <Header toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
      )}
      <Sidebar isOpen={isSidebarOpen} isMobile={isMobile} onClose={() => setIsSidebarOpen(false)} userRole={user.role}  />
      <main 
        className={`pt-16 transition-all duration-300 ${
          isSidebarOpen && !isMobile ? 'md:ml-70' : 'ml-0'
        }`}
      >
        <div className="p-3 md:p-4">
          <Outlet />
        </div>
      </main>
      <ScrollToTop />
      <Footer /> {/* Include the Footer component */}  

    </div>
  );
};

export default Layout;




const RouteTracker = () => {  
    const location = useLocation(); // Get the current location from react-router  

    useEffect(() => {  
        // Define the event handler  
        const handleBeforeUnload = () => {  
            // Store the current route in localStorage  
            localStorage.setItem('lastRoute', location.pathname);  
        };  

        // Add the beforeunload event listener  
        window.addEventListener('beforeunload', handleBeforeUnload);  

        // Cleanup the event listener on component unmount  
        return () => {  
            window.removeEventListener('beforeunload', handleBeforeUnload);  
        };  
    }, [location]); // Re-run effect when the location changes  

    return null; // This component does not render anything  
};  