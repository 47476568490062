import React from 'react';
import { Candidate } from '../../../types/candidate';
import CandidateCard from '../CandidateCard/CandidateCard';

interface CandidateGridProps {
  candidates: Candidate[];
  onEdit: (candidate: any) => any;
  onDelete: (id: string) => void;
  isSuperAdmin: boolean;
}

const CandidateGrid: React.FC<any> = ({
  candidates,
  onEdit,
  onDelete,
  setLoader,
  isSuperAdmin,
  isAccentureTeam
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {candidates.map((candidate:any) => (
        <CandidateCard
          key={candidate.id}
          candidate={candidate}
          setLoader={setLoader}
          onEdit={()=>onEdit(candidate)}
          onDelete={()=>onDelete(candidate)}
          isSuperAdmin={isSuperAdmin}
          isAccentureTeam={isAccentureTeam}
        />
      ))}
    </div>
  );
};

export default CandidateGrid;