import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
import EventModal from "./EventModal";
//@ts-ignore
import { app } from "../firebase";
import { setEventsValue } from "../store/eventsSlice";
//@ts-ignore
import SchedulesExports from "../SchedulesExport";
import { SearchControls } from "./SearchControls";
//@ts-ignore
import { InterviewCard } from "./InterviewCard";
import { serializeToIST } from "../components/utils/dateUtils";

// Move Firebase setup outside component
const db = getFirestore(app);

interface CompletedSchedulesProps {
  setLoader: (loading: boolean) => void;
  addToast: (message: string, type: string) => void;
}

interface Interview {
  id: string;
  candidateName?: string;
  companyName?: string;
  candidateEmail?: string;
  date: string;
  start: string;
  end: string;
  isCompleted: boolean;
  [key: string]: any;
}

const CompletedSchedules: React.FC<any> = React.memo(
  ({ setLoader, addToast }) => {
    // State management
    const [selectedDate, setSelectedDate] = useState(() => {
      const today = new Date();
      return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [selectedEvent, setSelectedEvent] = useState<Interview | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const prevSchedulesRef = useRef<any[]>([]);
    const [candidates, setCandidates] = useState([]);
    const [selectedSlotEndTime, setSelectedSlotEndTime] = useState(null);
    const [selectedSlotStartTime, setSelectedSlotStartTime] = useState(null);

    // Redux
    const schedulesData = useSelector((state: any) => state.events.events);
    const dispatch = useDispatch();

    // Filter interviews - memoized callback with proper dependencies
    const filterInterviews = useMemo(() => {
      return schedulesData.filter((interview: Interview) => {
        const interviewDate = new Date(interview.date)
          .toISOString()
          .slice(0, 10);
        const matchesDate = interviewDate === selectedDate;
        const trimmedSearchTerm = searchTerm.trim().toLowerCase();

        if (!matchesDate) return false;
        if (!trimmedSearchTerm) return true;

        return [
          interview.candidateName,
          interview.companyName,
          interview.candidateEmail,
        ].some((field) => field?.toLowerCase().includes(trimmedSearchTerm));
      });
    }, [selectedDate, searchTerm, schedulesData]);

    // Handle window resize with debounce
    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      const debounceResize = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(handleResize, 150);
      };

      window.addEventListener("resize", debounceResize);
      return () => {
        window.removeEventListener("resize", debounceResize);
        clearTimeout(timeoutId);
      };
    }, []);

    // Fetch data from Firestore - separated from filtering logic
    useEffect(() => {
      setLoader(true);

      const unsubscribe = onSnapshot(
        collection(db, "BDSchedules"),
        async (querySnapshot) => {
          try {
            const schedules: any = querySnapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
                start: serializeToIST(doc.data()?.start?.seconds * 1000),
                end: serializeToIST(doc.data()?.end?.seconds * 1000),
                date: serializeToIST(doc.data()?.date?.seconds * 1000),
              }))
              .filter((e: any) => e.isCompleted === true);

            // Only dispatch if the schedules have changed
            if (
              JSON.stringify(schedules) !==
              JSON.stringify(prevSchedulesRef.current)
            ) {
              dispatch(setEventsValue(schedules));
              prevSchedulesRef.current = schedules; // Update the ref
            }
          } catch (error: any) {
            addToast(error.message, "error");
          } finally {
            setLoader(false);
          }
        },
        (error) => {
          addToast(error.message, "error");
          setLoader(false);
        }
      );

      const candidatesUnsubscribe = onSnapshot(
        collection(db, "BDProfiles"),
        (querySnapshot) => {
          setLoader(true); // Show loader while fetching data
          const candidatesData = querySnapshot.docs.map((doc) => doc.data());
          setCandidates(candidatesData); // Update candidates state
          setLoader(false); // Hide loader after fetching data
        },
        (error) => {
          addToast(error.message, "error"); // Handle errors
          setLoader(false); // Hide loader in case of error
        }
      );

      return () => {
        candidatesUnsubscribe();
        unsubscribe();
      };
    }, [db, dispatch]);

    // Event handlers
    const handleDateChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(e.target.value);
      },
      []
    );

    const handleSearchChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
      },
      []
    );

    const handleClose = useCallback(() => {
      setModalOpen(false);
      setIsUpdate(false);
      setSelectedEvent(null);
    }, []);

    const handleSaveEvent = useCallback(
      async (eventData: any) => {
        try {
          if (selectedEvent) {
            await updateDoc(
              doc(db, "BDSchedules", selectedEvent.id),
              eventData
            );
            addToast("Event updated successfully", "success");
          }
          handleClose();
        } catch (error: any) {
          addToast(error.message, "error");
        }
      },
      [selectedEvent, addToast, handleClose]
    );

    const handleDeleteEvent = useCallback(
      async (event: Interview) => {
        try {
          await deleteDoc(doc(db, "BDSchedules", event.id));
          addToast("Event deleted successfully", "success");
        } catch (error: any) {
          addToast(error.message, "error");
        }
      },
      [addToast]
    );

    const onEdit = useCallback((event: Interview) => {
      setSelectedEvent(event);
      setModalOpen(true);
      setIsUpdate(true);
    }, []);

    const onDelete = useCallback((event: Interview) => {
      setSelectedEvent(event);
      setModalOpen(true);
      setIsUpdate(false);
    }, []);

    return (
      <div className="container max-w-full mx-auto p-0">
        <h1 className="flex items-center justify-between w-full max-w-md text-xl font-bold text-[#0071bd] mb-4 bg-gray-100 p-3 rounded-md shadow-md">
          <span>Completed Interview Schedules</span>
          <span className="bg-[#0071bd] text-white text-sm font-semibold px-2 py-1 rounded-full">
            {filterInterviews.length}
          </span>
        </h1>

        <SearchControls
          isMobile={isMobile}
          searchTerm={searchTerm}
          selectedDate={selectedDate}
          interviews={filterInterviews}
          onSearchChange={handleSearchChange}
          onDateChange={handleDateChange}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filterInterviews.map((interview: Interview) => (
            <InterviewCard
              key={interview.id}
              interview={interview}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </div>

        <EventModal
          event={selectedEvent}
          selectedSlotStartTime={selectedSlotStartTime}
          selectedSlotEndTime={selectedSlotEndTime}
          isOpen={modalOpen}
          onClose={handleClose}
          onSave={handleSaveEvent}
          onDelete={handleDeleteEvent}
          isUpdate={isUpdate}
          candidates={candidates}
        />
      </div>
    );
  }
);

CompletedSchedules.displayName = "CompletedSchedules";
export default CompletedSchedules;
