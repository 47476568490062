import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Phone, Mail, Edit2, Trash2, FileText, User } from "lucide-react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { app, storage } from "../firebase";
import { getAuth } from "firebase/auth";

const CandidateFormAddOrEdit = ({ addToast, setLoader, onClose }) => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const { selectedCandidate } = useSelector((state) => state.candidates);

  const [candidate, setCandidate] = useState({
    fullName: "",
    emailId: "",
    technology: "",
    contact: "",
    experience: "",
    interviewProgress: "",
    isPayed: false,
    isOffered: false,
    isSelected: false,
    balance: "",
    paidAmount: "",
    resume: "",
    candidateEmailPassword: "",
    cid: "",
    relevantExperience: "",
    previousCompanyName: "",
    currentCompanyName: "",
    currentCTC: "",
    noticePeriod: "",
    currentLocation: "",
    prefferedLocation: "",
    firstRoundPanelName: "",
    secondRoundPanelName: "",
    referenceName: "",
    handledBy: "",
    vendorName: "",
  });
  const [isDuplicate, setIsDuplicate] = useState(false);

  useEffect(() => {
    if (selectedCandidate) {
      setCandidate((prevCandidate) => ({
        ...prevCandidate,
        fullName: selectedCandidate?.fullName || "",
        emailId: selectedCandidate?.emailId || "",
        technology: selectedCandidate?.technology || "",
        contact: selectedCandidate?.contact || "",
        experience: selectedCandidate.experience || "",
        interviewProgress: selectedCandidate.interviewProgress || "",
        isPayed: selectedCandidate.isPayed || false,
        isOffered: selectedCandidate.isOffered || false,
        isSelected: selectedCandidate.isSelected || false,
        balance: selectedCandidate.balance || "",
        paidAmount: selectedCandidate.paidAmount || "",
        resume: selectedCandidate.resume || "",
        url: selectedCandidate.url || "",
        candidateEmailPassword: selectedCandidate.candidateEmailPassword || "",
        cid: selectedCandidate.cid || "",
        relevantExperience: selectedCandidate.relevantExperience || "",
        previousCompanyName: selectedCandidate.previousCompanyName || "",
        currentCompanyName: selectedCandidate.currentCompanyName || "",
        currentCTC: selectedCandidate.currentCTC || "",
        noticePeriod: selectedCandidate.noticePeriod || "",
        currentLocation: selectedCandidate.currentLocation || "",
        prefferedLocation: selectedCandidate.prefferedLocation || "",
        firstRoundPanelName: selectedCandidate.firstRoundPanelName || "",
        secondRoundPanelName: selectedCandidate.secondRoundPanelName || "",
        referenceName: selectedCandidate.referenceName || "",
        handledBy: selectedCandidate.handledBy || "",
        vendorName: selectedCandidate.vendorName || "",
      }));
    }
    scrollToTop();
  }, [selectedCandidate]);

  const handleAddProfileSubmit = async (newProfileData) => {
    setLoader(true); // Show loader while processing
    const data = {
      ...newProfileData, // Use the new profile data submitted
      createdBy: user?.emailId || null, // Set the createdBy field to the current user's email ID
      createdRole: user?.role || null, // Set the createdRole field to the current user's role
    };

    // Handle file upload if a file is attached
    if (file !== null) {
      const storageRef = ref(storage, `files/${file.name}`); // Reference for storage
      try {
        await uploadBytes(storageRef, file); // Upload the file to storage
        const url = await getDownloadURL(storageRef); // Get download URL
        await addDoc(collection(db, "files"), { name: file.name, url }); // Add file to Firestore
        data.url = url; // Set the URL in the candidate data
        data.resume = file.name; // Save the file name in the candidate data
      } catch (uploadError) {
        addToast(uploadError.error, "error");
        setLoader(false); // Hide loader on error
        return; // Exit the function on error
      }
    }

    try {
      if (candidate?.id || selectedCandidate?.id) {
        handleEditDetails(data);
      } else {
        createCandidates(data); // Add the new profile data to the candidates collection
      }
    } catch (error) {
      addToast(error.message, "error");
      setLoader(false); // Ensure the loader is hidden on error
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleEditDetails = async (data) => {
    const body = {
      ...data,
      id: selectedCandidate.id,
    };
    if (!body.hasOwnProperty("createdBy")) {
      body.createdBy = user?.emailId;
    }
    if (!body.hasOwnProperty("createdRole")) {
      body.createdRole = user?.role;
    }
    if (body?.file) delete body.file;
    try {
      await updateCandidates(selectedCandidate.id, body);
      addToast("Candidate updated successfully", "success");
      setLoader(false); // Hide the loader after successful submission
      onClosed(); // Close the popup after successful submission
    } catch (error) {
      addToast(error.message, "error");
      setLoader(false); // Ensure the loader is hidden on error
    }
  };

  const updateCandidates = async (id, data) => {
    await updateDoc(doc(db, "BDProfiles", id), data);
  };
  

  const handleDeleteFile = async () => {
    if (candidate.resume) {
      try {
        setLoader(true);
        // Delete the file from Firebase Storage
        const fileRef = ref(storage, `files/${candidate.resume}`);
        await deleteObject(fileRef);
        setFile(null);
        setCandidate({ ...candidate, resume: "", url: "" });

        // Delete the file metadata from Firestore
        const q = query(
          collection(db, "files"),
          where("name", "==", candidate.resume)
        );
        const querySnapshot = await getDocs(q);

        // Use a for...of loop to handle async delete operations
        for (const doc of querySnapshot?.docs) {
          await deleteDoc(doc.ref); // Await the deletion of each document
        }

        await updateCandidates(selectedCandidate.id, {
          ...selectedCandidate,
          resume: null,
          url: null,
        });
        
        addToast("Candidate file deleted successfully", "success");
        setLoader(false);
      } catch (error) {
        addToast(error.message, "error");
        setLoader(false);
      }
    }
  };

  const createCandidates = async (data) => {
    try {
      // Prepare Data for adding to Firestore
      data.isSelected = data?.isSelected || false;
      data.isOffered = data?.isOffered || false;
      data.isPayed = data?.isPayed || false;
      data.interviewProgress = data?.interviewProgress || null;
      data.resume = data?.resume || null;
      data.url = data?.url || null;
      data.balance = data?.balance || 0;
      data.paidAmount = data?.paidAmount || 0;
      data.createdBy = user?.emailId || null;
      data.createdRole = user?.role || null;
      const newCandidateRef = doc(collection(db, "BDProfiles")); // Create a reference with a generated ID

      // Add the generated ID to the data object
      const candidateDataWithId = {
        id: newCandidateRef.id, // Assign the generated ID
        ...data, // Spread the existing data
      };

      // Now add the document to Firestore with the ID
      await setDoc(newCandidateRef, candidateDataWithId); // Use the reference to add the document
      setLoader(false); // Hide the loader after successful submission
      addToast("Candidate added successfully", "success");
      onClosed(); // Close the popup after successful submission
    } catch (error) {
      addToast("Error registering candidate", "error");
      setLoader(false);
    }
  };

  const [role, setRole] = useState(user?.role || "ADMIN"); // Default role
  const navigate = useNavigate();

  const getCandidateByEmail = async (email) => {
    const q = query(
      collection(db, "BDProfiles"),
      where("emailId", "==", email)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  };

  const checkCandidateByEmail = async (e) => {
    setIsDuplicate(false);
    const { name, value, type, checked } = e.target;
    if (name == "emailId") {
      const email = value;
      const candidates = await getCandidateByEmail(email);
      if (candidates.length > 0) {
        if (!selectedCandidate || !selectedCandidate?.hasOwnProperty("id")) {
          addToast("Candidate with this email already exists", "error");
          setIsDuplicate(true);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    checkCandidateByEmail(e);
    setCandidate({
      ...candidate,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the uploaded file
    if (file) {
      setFile(file); // Set the file state
      setCandidate({ ...candidate, resume: file.name }); // Store the file name in the resume key
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddProfileSubmit(candidate);
  };

  const onClosed = () => {
    navigate("/candidates/all"); // Use navigate for routing
  };

  return (
    <div className="overlay">
      <div className="container p-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto relative">
        <button
          onClick={onClosed}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-xl font-bold text-[#0071bd] mb-4 bg-gray-100 p-2 mt-4 rounded-md">
          {selectedCandidate?.id ? "Edit Candidate" : "Add Candidate"}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            {Object.keys(candidate).map((key) => {
              // Define fields for each role
              const superAdminFields = ["paidAmount", "balance"];

              const adminFields = [
                "contact",
                "createdBy",
                "createdRole",
                "emailId",
                "experience",
                "fullName",
                "handledBy",
                "interviewProgress",
                "isOffered",
                "isPayed",
                "isSelected",
                "technology",
                "vendorName",
                "resume",
              ];

              const accentureTeamFields = [
                "candidateEmailPassword",
                "cid",
                "currentCompanyName",
                "currentLocation",
                "currentCTC",
                "firstRoundPanelName",
                "noticePeriod",
                "prefferedLocation",
                "previousCompanyName",
                "referenceName",
                "relevantExperience",
                "secondRoundPanelName",
              ];

              // Determine if the field should be rendered based on the role
              if (role === "ADMIN" && !adminFields.includes(key)) {
                return null; // Skip rendering fields not in the admin fields
              }

              if (
                role === "SUPERADMIN" &&
                ![...adminFields, ...superAdminFields].includes(key)
              ) {
                return null; // Skip rendering fields not in the combined list of admin and super admin fields
              }

              if (
                role === "ACCENTURETEAM" &&
                ![...adminFields, ...accentureTeamFields].includes(key)
              ) {
                return null; // Skip rendering fields not in the combined list of admin and accenture team fields
              }

              return (
                <div key={key} className="flex flex-col">
                  <label
                    style={{ textTransform: "capitalize" }}
                    className="font-semibold"
                  >
                    {key.replace(/([A-Z])/g, " $1")}
                  </label>
                  {key === "resume" ? ( // Special handling for resume upload
                    <>
                      {candidate.resume && candidate.url ? ( // Check if both resume and URL exist
                        <div className="flex items-center justify-between bg-[#0071bd] bg-opacity-10 p-2 border border-[#0071bd] rounded mt-1">
                          <div className="flex items-center">
                            <FileText className="w-6 h-6 text-[#0071bd] font-bold mr-4" />
                            <span className="text-[#0071bd]">
                              {"Candidate_Resume" +
                                "." +
                                candidate.resume.split(".")[1]}
                            </span>
                          </div>
                          <button
                            type="button"
                            onClick={handleDeleteFile}
                            className="text-red-600 hover:text-red-800"
                          >
                            <svg
                              className="w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <input
                          type="file"
                          name={key}
                          onChange={handleFileChange}
                          className="border rounded p-2 mt-1"
                        />
                      )}
                    </>
                  ) : key === "experience" ? ( // Dropdown for experience
                    <select
                      name={key}
                      value={candidate[key]}
                      onChange={handleChange}
                      className="border rounded p-2 mt-1"
                    >
                      <option value="" disabled>
                        Select Experience
                      </option>
                      <option value="2+ years">2+ years</option>
                      <option value="3+ years">3+ years</option>
                      <option value="4+ years">4+ years</option>
                      <option value="1+ years">1+ years</option>
                      <option value="5+ years">5+ years</option>
                      <option value="6+ years">6+ years</option>
                      <option value="7+ years">7+ years</option>
                    </select>
                  ) : key === "interviewProgress" ? ( // Dropdown for interview progress
                    <select
                      name={key}
                      value={candidate[key]}
                      onChange={handleChange}
                      className="border rounded p-2 mt-1"
                    >
                      <option value="" disabled>
                        Select Interview Progress
                      </option>
                      <option value="L1 Completed">L1 Completed</option>
                      <option value="Waiting for L1">Waiting for L1</option>
                      <option value="L1 Rejected">L1 Rejected</option>
                      <option value="L1 Completed Waiting for Update">
                        L1 Completed Waiting for Update
                      </option>
                      <option value="L2 Completed">L2 Completed</option>
                      <option value="L2 Rejected">L2 Rejected</option>
                      <option value="Interviews Completed waiting for update">
                        Interviews Completed waiting for update
                      </option>
                      <option value="Waiting for L2">Waiting for L2</option>
                      <option value="L2 Completed Waiting for Update">
                        L2 Completed Waiting for Update
                      </option>
                      <option value="Waiting for Documentation">
                        Waiting for Documentation
                      </option>
                      <option value="Documentation Completed Waiting for Offer">
                        Documentation Completed Waiting for Offer
                      </option>
                      <option value="Offered">Offered</option>
                    </select>
                  ) : ["isPayed", "isSelected", "isOffered"].includes(key) ? (
                    <div className="flex flex-col space-x-4 mt-1">
                      <div
                        key={key}
                        className="relative inline-flex items-center"
                      >
                        <label className="flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name={key}
                            checked={candidate[key]}
                            onChange={handleChange}
                            className="sr-only"
                          />
                          <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
                          <div
                            className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                              candidate[key]
                                ? "translate-x-full bg-[#0071bd]"
                                : "bg-gray-500"
                            }`}
                          ></div>
                        </label>
                      </div>
                    </div>
                  ) : key === "paidAmount" && user.role === "SUPERADMIN" ? (
                    <input
                      type="number"
                      name={key}
                      value={candidate[key]}
                      onChange={handleChange}
                      placeholder="Enter Paid Amount"
                      className="border rounded p-2 mt-1"
                    />
                  ) : key === "balance" && user.role === "SUPERADMIN" ? (
                    <input
                      type="number"
                      name={key}
                      value={candidate[key]}
                      onChange={handleChange}
                      placeholder="Enter Balance"
                      className="border rounded p-2 mt-1"
                    />
                  ) : (
                    <input
                      type={
                        typeof candidate[key] === "number" ? "number" : "text"
                      }
                      name={key}
                      value={candidate[key]}
                      onChange={handleChange}
                      placeholder={
                        key === "technology"
                          ? "Ex: ADF, Java, Dotnet, Reactjs"
                          : ""
                      } // Placeholder for technologies
                      className="border rounded p-2 mt-1"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex justify-between mt-4">
            <button
              type="submit"
              disabled={isDuplicate}
              className="bg-[#0071bd] text-white px-4 py-2 rounded transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#005f8b]"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClosed}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded transition duration-200 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CandidateFormAddOrEdit;
