import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DailyScheduleTable from './DailyScheduleTable';

const SchedulingProfilesPage = ({setLoader,
    addToast, user}) => {
  const { status } = useParams<{ status: string }>();
  const [profiles, setProfiles] = useState<any[]>([]);
  const [tempProfiles , setTempProfiles] = useState<any[]>([]);

  return (
    <div className="p-0 ">
      <h1 className="text-2xl font-bold text-[#0071bd] mb-6  bg-gray-100 p-3 rounded-md shadow-md">
       Today's Registered Profiles for Scheduling
       <span className="bg-[#0071bd]  ml-6 text-white text-sm font-semibold px-2 py-1 rounded-full">
            {profiles.length}
          </span>
      </h1>
      <DailyScheduleTable setLoader={setLoader} addToast={addToast} profiles={profiles} tempProfiles={tempProfiles} setProfiles={setProfiles} setTempProfiles={setTempProfiles} user={user} />
    </div>
  );
};

export default SchedulingProfilesPage;