import { Building, Calendar, Edit, Mail, Trash2, User } from "lucide-react";
import React, { useState, useCallback } from "react";

// interface InterviewCardProps {
//   interview: any;
//   onEdit: (interview: any) => void;
//   onDelete: (interview: any) => void;
// }

export const InterviewCard= React.memo(({
  interview,
  onEdit,
  onDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  const handleEdit = useCallback(() => {
    onEdit(interview);
  }, [interview, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(interview);
  }, [interview, onDelete]);

  return (
    <div className="border border-gray-200 rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out p-6 relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#0071bd] leading-tight">
          {interview.title}
        </h2>
        <div className="flex gap-2">
          {/* <button
            onClick={handleEdit}
            className="p-2 text-[#0071bd] hover:bg-[#0071bd] hover:bg-opacity-10 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"
          >
            <Edit className="w-5 h-5" />
          </button>
          <button
            onClick={handleDelete}
            className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors duration-300 ease-in-out transform hover:scale-110"
          >
            <Trash2 className="w-5 h-5" />
          </button> */}
        </div>
      </div>

      <div className="mt-2 text-sm text-gray-600 space-y-2">
        <div className="flex items-center gap-x-2">
          <User className="w-5 h-5 text-gray-500" />
          <p className="font-semibold text-gray-900">
            {interview.candidateName || "N/A"}
          </p>
        </div>
        <div className="flex items-center gap-x-2">
          <Mail className="w-5 h-5 text-gray-500" />
          <p className="font-semibold text-gray-900">
            {interview.candidateEmail || "N/A"}
          </p>
        </div>

        <div className="flex items-center gap-2 bg-gray-100 p-2 rounded-md shadow-sm">
          <TimeDisplay time={interview.start} />
          <span className="text-gray-500 text-sm">to</span>
          <TimeDisplay time={interview.end} />
        </div>
      </div>

      <p className="mt-4 text-sm font-medium text-gray-500 flex items-center">
        <span
          className={`px-3 py-1 rounded-full font-semibold text-sm ${
            interview.isCompleted
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {interview.isCompleted ? "Completed" : "Pending"}
        </span>
      </p>

      <div className="border-t border-gray-300 my-4"></div>

      {isExpanded && (
        <ExpandedContent interview={interview} />
      )}

      <button
        onClick={toggleExpand}
        className="mt-2 text-sm font-semibold text-[#0071bd] hover:underline focus:outline-none"
      >
        {isExpanded ? "Show Less" : "Show More"}
      </button>
    </div>
  );
});

const TimeDisplay = React.memo(({ time }) => (
  <span className="font-medium text-gray-900 text-base">
    {new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })}
  </span>
));

const ExpandedContent = React.memo(({ interview }) => (
  <div className="text-sm text-gray-600 space-y-2">
    <div className="flex gap-x-2">
      <span className="text-sm text-gray-500 font-medium">Round:</span>
      <p className="font-semibold text-gray-900">
        {interview.roundDetails || "N/A"}
      </p>
    </div>
    <div className="flex gap-x-2">
      <span className="text-sm text-gray-500 font-medium">Panel Name:</span>
      <p className="font-semibold text-gray-900">
        {interview.panelName || "N/A"}
      </p>
    </div>
    <div className="flex gap-x-2">
      <span className="text-sm text-gray-500 font-medium">Panel Email:</span>
      <p className="font-semibold text-gray-900">
        {interview.panelEmail || "N/A"}
      </p>
    </div>
    <div className="flex items-center gap-x-2">
      <Building className="w-5 h-5 text-gray-500" />
      <p className="font-semibold text-gray-900">
        {interview.companyName || "N/A"}
      </p>
    </div>
    <div className="flex gap-x-2">
      <Calendar className="w-5 h-5 text-gray-500" />
      <p className="font-semibold text-gray-900">
        {new Date(interview.date).toDateString()}
      </p>
    </div>
  </div>
));

TimeDisplay.displayName = "TimeDisplay";
ExpandedContent.displayName = "ExpandedContent";
InterviewCard.displayName = "InterviewCard";