import React from 'react';

interface StatusBadgeProps {
  status: string;
}

const StatusBadge: React.FC<any> = ({ role, status }) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'l1 completed':
        return 'bg-green-100 text-green-800';
      case 'l1 rejected':
        return 'bg-red-100 text-red-800';
      case 'waiting for l1':
        return 'bg-yellow-100 text-yellow-800';
      case 'offered':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(status)}`}>
      {role ==true ? status :'Offered and Joined in Job'}
    </span>
  );
};

export default StatusBadge;