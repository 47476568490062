import React, { useState, useRef, useEffect } from "react";
import { Download, Plus, Trash, Upload, X } from "lucide-react";
import { ExcelIcon, GreenDeleteIcon } from "../../components/Icons";
import { Profile } from "./types.ts";
import ConfirmationPopup from "../../components/ConfirmationPopup.jsx";
import { utils, writeFile } from "xlsx"; // Import necessary functions from SheetJS
// import { utils, writeFile } from "xlsx-style";
import * as XLSX from "xlsx"; // Import the xlsx library
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  query,
  where,
  setDoc,
  onSnapshot,
  getDoc,
  Timestamp,
} from "firebase/firestore";
//@ts-ignore
import { app, storage } from "../../firebase";
import { getAuth } from "firebase/auth";

interface ProfileTableProps {
  setLoader: (loading: boolean) => void;
  addToast: (message: string, type: string) => void;
  profiles: Profile[];
  tempProfiles: Profile[];
  setProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
  setTempProfiles: React.Dispatch<React.SetStateAction<Profile[]>>;
  user: any;
}

const DailyScheduleTable: React.FC<ProfileTableProps> = ({
  setLoader,
  addToast,
  profiles,
  tempProfiles,
  setProfiles,
  setTempProfiles,
  user,
}) => {
  const db = getFirestore(app);
  const auth = getAuth(app);
  const [isActive, setIsActive] = useState<boolean>(true);
  // Define a ref to hold the timeout ID
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const inportFileInputRef = useRef<HTMLInputElement>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<any>(false);
  // Initialize state with today's date
  const [currentDateValue, setCurrentDateValue] = useState<any>(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const currentDateValueRef = useRef(null);
  const [selectedProfileToDelete, setSelectedProfileToDelete] =
    useState<any>(null);
  const [userLoggedIn, setuserLoggedIn] = useState<any>(user);

  const graduationOptions = [
    "B.Tech",
    "M.Tech",
    "MCA",
    "MSC",
    "B.Com",
    "M.Com",
    "Degree",
  ];

  const technologyOptions = [
    "Java ",
    "Dotnet",
    "Java Full Stack",
    "Dotnet Full Stack",
    "Angular UI",
    "React UI",
    "Devops AWS",
    "Devops Azure",
    "Testing Automation",
    "Playwright Testing",
    "Power Apps",
    "ETL Informatica",
  ];
  const yearsOfExperienceOptions = [
    "2+ years",
    "3+ years",
    "4+ years",
    "5+ years",
    "6+ years",
    "7+ years",
  ];

  const [newRow, setNewRow] = useState<Profile>({
    id: "",
    fullName: "",
    emailId: "",
    mobileNo: "",
    resume: "",
    technology: "",
    yearsOfExperience: "",
    referenceName: "",
    url: "",
  });

  useEffect(() => {
    // Ensure currentDateValue is a valid date string
    if (!currentDateValue) {
      console.error("currentDateValue is empty or undefined");
      return; // Exit if currentDateValue is invalid
    }

    // Create a Date object from currentDateValue
    const selectedDate = new Date(currentDateValue);

    // Check if the Date object is valid
    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date value:", currentDateValue);
      return; // Exit if the date is invalid
    }

    // Set start and end of the day
    const startOfDay = new Date(selectedDate); // Create a new Date object for startOfDay
    startOfDay.setHours(0, 0, 0, 0); // Start of the day

    const endOfDay = new Date(selectedDate); // Create a new Date object for endOfDay
    endOfDay.setHours(23, 59, 59, 999); // End of the day

    // Log the timestamps for debugging
    console.log("startOfDay:", Timestamp.fromDate(startOfDay));
    console.log("endOfDay:", Timestamp.fromDate(endOfDay));

    // Prepare the Firestore query
    const q = query(
      collection(db, "SchedulingProfiles"),
      where("createdDate", ">=", Timestamp.fromDate(startOfDay)), // Compare with start of the day
      where("createdDate", "<=", Timestamp.fromDate(endOfDay)) // Compare with end of the day
    );

    // Set up the snapshot listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        setLoader(true); // Start loading before processing
        const result = querySnapshot.docs.map((doc) => {
          const data = doc.data(); // Get the document data
          return {
            ...data,
            createdDate: data.createdDate
              ? data.createdDate.seconds
                ? new Date(data.createdDate.seconds * 1000)
                : data.createdDate.toDate()
              : null, // Convert Firestore timestamp or Unix timestamp to Date
          };
        });
        setProfiles(result as Profile[]); // Update state with profiles
        setTempProfiles(result as Profile[]); // Update temporary profiles
        setLoader(false); // Stop loading after processing
      },
      (error) => {
        addToast(error.message, "error"); // Handle errors
        setLoader(false); // Stop loading on error
      }
    );

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      unsubscribe();
    };
  }, [currentDateValue, userLoggedIn?.emailId]); // Add dependencies to the effect

  // useEffect(() => {

  //   const selectedDate = new Date(currentDateValue);
  //   const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)); // Start of the day
  //   const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999)); // End of the day

  //   console.log("startOfDay:" + " " + Timestamp.fromDate(startOfDay));
  //   console.log("endOfDay:" + " " + Timestamp.fromDate(endOfDay));
  //   const q = query(
  //     collection(db, "CandidateProfiles"),
  //     where("createdBy", "==", userLoggedIn?.emailId),
  //     where("createdDate", ">=", Timestamp.fromDate(startOfDay)), // Compare with start of the day
  //     where("createdDate", "<=", Timestamp.fromDate(endOfDay)) // Compare with end of the day
  //   );
  //   // Set up the snapshot listener
  //   const unsubscribe = onSnapshot(
  //     q, // Use the defined query here
  //     (querySnapshot) => {
  //       setLoader(true); // Start loading before processing
  //       const result = querySnapshot.docs.map((doc) => {
  //         const data = doc.data(); // Get the document data
  //         return {
  //           ...data,
  //           createdDate: data.createdDate
  //             ? data.createdDate.seconds
  //               ? new Date(data.createdDate.seconds * 1000)
  //               : data.createdDate.toDate()
  //             : null, // Convert Firestore timestamp or Unix timestamp to Date
  //         };
  //       });
  //       setProfiles(result as Profile[]); // Update state with profiles
  //       setTempProfiles(result as Profile[]); // Update temporary profiles
  //       setLoader(false); // Stop loading after processing
  //     },
  //     (error) => {
  //       addToast(error.message, "error"); // Handle errors
  //       setLoader(false); // Stop loading on error
  //     }
  //   );

  //   return () => {
  //     if (debounceTimeout.current) {
  //       clearTimeout(debounceTimeout.current);
  //     }
  //     unsubscribe();
  //   };
  // }, [currentDateValue]);

  const handleInputChange = async (
    field: keyof Profile,
    value: any,
    profileId?: string
  ) => {
    if (profileId) {
      const updatedProfile = {
        ...profiles.find((p) => p.id === profileId),
        [field]: value,
      };

      setProfiles((prev) =>
        prev.map((p) => (p.id === profileId ? updatedProfile : p))
      );

      // Set a new timeout for updating the profile
      debounceTimeout.current = setTimeout(async () => {
        await updateSchedulingProfile(profileId, updatedProfile);
      }, 10000); // 10 seconds debounce time
    } else {
      // Update the new row state immediately for instant feedback
      setNewRow((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    profileId?: string,
    index?: number
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoader(true);

      const profileData = profileId
        ? profiles.find((p) => p.id === profileId)
        : newRow;

      if (!profileData) {
        throw new Error("Profile not found");
      }

      if (profileId) {
        const storageRef = ref(storage, `files/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        await addDoc(collection(db, "files"), {
          name: file.name,
          url,
        });
        const updatedProfile = {
          ...profileData,
          resume: file.name,
          url,
        };

        if (profileId) {
          await updateSchedulingProfile(profileId, updatedProfile);
          setProfiles((prev) =>
            prev.map((p) => (p.id === profileId ? updatedProfile : p))
          );
        } else {
          setNewRow(updatedProfile);
        }

        addToast("File uploaded successfully", "success");
      } else {
        const updatedProfile = {
          ...profileData,
          resumeFile: file,
          resume: file.name,
        };
        setNewRow(updatedProfile);
      }
    } catch (error) {
      addToast(error.message || "Error uploading file", "error");
    } finally {
      setLoader(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const downloadFile = async (fileUrl, name) => {
    setLoader(true);
    if (!fileUrl || fileUrl == "") {
      setLoader(false);
      addToast("File Not Exists", "error");
      return;
    }
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        setLoader(false);
        addToast("Network response was not ok", "error");
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); // Provide a name for the file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const removeFile = async (profileId?: string) => {
    const profile = profileId
      ? profiles.find((p) => p.id === profileId)
      : newRow;

    if (!profile?.resume) return; // Early exit if there is no resume to remove

    try {
      setLoader(true);
      const fileRef = ref(storage, `files/${profile.resume}`);
      await deleteObject(fileRef);

      const q = query(
        collection(db, "files"),
        where("name", "==", profile.resume)
      );
      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        await deleteDoc(doc.ref);
      }

      addToast("File removed successfully", "success");
    } catch (error) {
      addToast(extractString(error.message) || "Error removing file", "error");
    } finally {
      setLoader(false);
    }

    // Update the profile after deletion attempt, regardless of success/failure
    const updatedProfile = {
      ...profile,
      resume: "",
      url: "",
    };

    if (profileId) {
      await updateSchedulingProfile(profileId, updatedProfile);
      setProfiles((prev) =>
        prev.map((p) => (p.id === profileId ? updatedProfile : p))
      );
    } else {
      setNewRow(updatedProfile);
    }
  };

  const extractString = (input) => {
    const start = input.indexOf("'") - 1; // Find index after colon
    const end = input.lastIndexOf("."); // Find index of period

    // Validate indices
    if (start === -1 || end === -1 || start >= end) {
      return ""; // Return empty string if indices are invalid
    }

    return input.substring(start, end).trim(); // Extract and trim whitespace
  };

  const updateSchedulingProfile = async (
    profileId: string,
    data: Partial<Profile>
  ) => {
    try {
      data["updatedBy"] = userLoggedIn?.emailId;
      const docRef = doc(db, "SchedulingProfiles", profileId);
      await updateDoc(docRef, data);
    } catch (error) {
      addToast(error.message || "Error updating profile", "error");
    }
  };

  const addNewRow = async () => {
    if (!validateNewRow()) {
      addToast("Please fill all required fields", "warning");
      return;
    }

    try {
      let newRowData = {
        ...newRow,
      };
      setLoader(true);
      if (newRow?.resumeFile) {
        const storageRef = ref(storage, `files/${newRowData?.resume}`);
        await uploadBytes(storageRef, newRowData?.resumeFile);
        const url = await getDownloadURL(storageRef);

        await addDoc(collection(db, "files"), {
          name: newRowData?.resume,
          url,
        });
        newRowData = {
          ...newRowData,
          url,
        };
      }

      const newProfileRef = doc(collection(db, "SchedulingProfiles"));

      delete newRowData.resumeFile;

      const profileData = {
        ...newRowData,
        id: newProfileRef.id,
        createdBy: userLoggedIn?.emailId,
        createdDate: new Date(currentDateValue),
      };

      await setDoc(newProfileRef, profileData);
      // setProfiles((prev) => [...prev, profileData]);
      resetNewRow();
      addToast("Profile added successfully", "success");
    } catch (error) {
      addToast(error.message || "Error adding profile", "error");
    } finally {
      setLoader(false);
    }
  };

  const validateNewRow = () => {
    return (
      newRow.fullName &&
      newRow.emailId &&
      newRow.mobileNo &&
      newRow.technology &&
      newRow.yearsOfExperience &&
      newRow.referenceName
    );
  };

  const resetNewRow = () => {
    setNewRow({
      id: "",
      url: "",
      fullName: "",
      emailId: "",
      mobileNo: "",
      referenceName: "",
      yearsOfExperience: "",
      technology: "",
      resume: "",
    });
  };

  const handleDeleteProfile = async (profileId: string) => {
    try {
      setLoader(true);
      const profile = profiles.find((p) => p.id === profileId);

      if (profile?.resume) {
        await removeFile(profileId);
      }

      await deleteDoc(doc(db, "SchedulingProfiles", profileId));
      setProfiles((prev) => prev.filter((p) => p.id !== profileId));
      addToast("Profile deleted successfully", "success");
    } catch (error) {
      addToast(error.message || "Error deleting profile", "error");
    } finally {
      setLoader(false);
    }
  };

  const renderFileUpload = (profile?: Profile) => {
    const hasFile = profile ? profile.resume : newRow.resume;

    return (
      <div className="flex items-center gap-2">
        {hasFile ? (
          <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-[#0071bd] rounded-lg w-full">
            {/* Download Icon */}
            <button
              onClick={() =>
                downloadFile(profile ? profile.url : "", profile.resume)
              } // Adjust based on available URL
              className="text-[#0071bd] hover:text-blue-700 p-0.5 rounded-full hover:bg-blue-100"
            >
              <Download className="w-4 h-4" />{" "}
              {/* Assuming 'Download' is an appropriate icon */}
            </button>

            <span className="flex-1 truncate text-sm">
              {profile ? profile.resume : newRow.resume}
            </span>

            <button
              onClick={() => removeFile(profile?.id)}
              className="text-red-600 hover:text-red-900 p-0.5 rounded-full hover:bg-red-50"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <>
            <button
              onClick={() => fileInputRef.current?.click()}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-[#0071bd] border border-[#0071bd] rounded-lg hover:bg-[#0071bd] hover:text-white transition-colors w-full"
            >
              <Upload className="w-4 h-4" />
              <span>Upload Resume</span>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => handleFileUpload(e, profile?.id)}
              className="hidden"
              accept=".pdf,.doc,.docx"
            />
          </>
        )}
      </div>
    );
  };

  const renderEditableCell = (
    profile: Profile,
    field: keyof Profile,
    index: number
  ) => {
    if (field === "resume") {
      return renderFileUpload(profile);
    }

    if (field === "yearsOfExperience") {
      return (
        <select
          value={profile.yearsOfExperience}
          onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
          className=" min-w-[1rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded bg-white"
        >
          <option value="">Select Year</option>
          {yearsOfExperienceOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      );
    }
    if (field === "technology") {
      return (
        <select
          value={profile.technology}
          onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
          className=" min-w-[1rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded bg-white"
        >
          <option value="">Select Technology</option>
          {technologyOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      );
    }

    return (
      <input
        type={
          field === "emailId"
            ? "email"
            : field === "mobileNo"
            ? "tel"
            : field === "referenceName"
            ? "text"
            : "text" // Default fallback
        }
        value={profile[field] as string}
        onChange={(e) => handleInputChange(field, e.target.value, profile.id)}
        className="min-w-[3rem] px-2 py-1 border-0 focus:ring-2 focus:ring-[#0071bd] rounded"
        placeholder={
          field.charAt(0).toUpperCase() +
          field.slice(1).replace(/([A-Z])/g, " $1")
        }
      />
    );
  };

  const deleteRow = (profile: any) => {
    setIsConfirmDeleteOpen(true);
    setSelectedProfileToDelete(profile);

    //setProfiles((prev) => prev.filter((p) => p.id !== profile.id));
  };

  const onImport = (event) => {
    if (event?.target?.files?.length > 0) {
      var file: any = event?.target?.files[0];
    }
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

      // Check if the first sheet exists
      if (!firstSheet) {
        alert("No data found in the first sheet.");
        return;
      }

      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }); // Read data as an array of arrays

      // Check if jsonData has at least one row
      if (!jsonData || jsonData.length === 0) {
        alert("No data found in the sheet.");
        return;
      }

      // Convert jsonData to profiles format
      const importedProfiles = jsonData
        .slice(1)
        .map((row: any, index) => {
          // Check if row is defined and has enough columns
          if (!row || row?.length < 8) {
            console.warn(
              `Row ${index + 1} is invalid or does not have enough columns.`
            );
            return null; // or return a default object if needed
          }

          return {
            siNo: row[0] || "",
            fullName: row[1] || "",
            emailId: row[2] || "",
            mobileNo: row[3] || "",
            yearsOfExperience: row[4] || "",
            technology: row[5] || "",
            resume: "",
            referenceName: row[7] || "",
            url: "", // Assuming the URL is in the 9th column
          };
        })
        .filter((profile) => profile !== null); // Remove any null profiles caused by invalid rows

      // Convert importedProfiles to JSON format
      const jsonProfiles = JSON.stringify(importedProfiles);
      // Update the profiles state if you still need to keep the data in state
      registerImportedProfiles(JSON.parse(jsonProfiles));
      //   return jsonProfiles;
    };
  };

  const registerImportedProfiles = (jsonData) => {
    //SchedulingProfiles
    jsonData.foEach(async (profile) => {
      //SchedulingProfiles
      setLoader(true);
      const newProfileRef = doc(collection(db, "SchedulingProfiles"));
      delete newRow.resumeFile;
      const profileData = {
        ...profile,
        id: newProfileRef.id,
        createdBy: userLoggedIn?.emailId,
        createdDate: new Date(currentDateValue),
      };

      await setDoc(newProfileRef, profileData);
    });
    setLoader(false);
    addToast("Imported Successfully", "success");
  };

  const formatDateToDDMMMYYYY = (date) => {
    const today = new Date(date);
    const day = String(today.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
    const monthIndex = today.getMonth(); // Get the month (0-11)
    const year = today.getUTCFullYear(); // Get full year

    // Array of month abbreviations
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the abbreviated month
    const month = monthAbbreviations[monthIndex];

    // Return the formatted date
    return `${day}-${month}-${year}`; // Format: dd-mmm-yyyy
  };

  const downloadAsExcel = () => {
    // Define the headers for the Excel file
    const headers = [
      "S.No",
      "Full Name",
      "Email",
      "Mobile",
      "Years Of Experience",
      "Technology",
      "Resume",
      "Reference Name",
      "Date",
    ];

    // Prepare the rows data
    const modifiedProfiles = profiles.map((p, i) => ({
      ...p,
      siNo: i + 1,
    }));

    const rows = modifiedProfiles.map((p) => [
      p.siNo,
      p.fullName || "", // Ensure no undefined values
      p.emailId || "", // Email as plain text (hyperlink formatting will be handled in Excel)
      p.mobileNo || "",
      p.yearsOfExperience || "",
      p.technology || "",
      p.resume || "",
      p.referenceName || "", // Use empty string if undefined
      formatDateToDDMMMYYYY(new Date(p.createdDate)), // Format the date
    ]);

    // Create a worksheet from the data
    const worksheet = utils.aoa_to_sheet([headers, ...rows]);

    // Set column widths based on the maximum length of the values in each column
    const columnWidths = [
      { wch: 5 }, // Width for "S.No"
      { wch: 25 }, // Width for "Full Name"
      { wch: 30 }, // Width for "Email"
      { wch: 10 }, // Width for "Mobile"
      { wch: 10 }, // Width for "Years Of Experience"
      { wch: 10 }, // Width for "Technology"
      { wch: 20 }, // Width for "Resume"
      { wch: 25 }, // Width for "Reference Name"
      { wch: 8 }, // Width for "Date"
    ];

    worksheet["!cols"] = columnWidths; // Set the widths for the worksheet columns

    // Apply styles to the header row (make it bold)
    for (let col = 0; col < headers.length; col++) {
      const cellAddress = utils.encode_cell({ r: 0, c: col }); // Header row is the first row (0 index)
      if (!worksheet[cellAddress]) {
        worksheet[cellAddress] = {}; // Create the cell if it doesn't exist
      }
      worksheet[cellAddress].s = {
        font: {
          bold: true,
        },
      };
    }

    // Apply styling to the header row
    for (let i in worksheet) {
      if (Number(i.toString()?.split("")[1]) === 1) {
        if (typeof worksheet[i] !== "object") continue;
        worksheet[i].s = {
          font: {
            name: "Arial",
            bold: true,
            color: { hex: "#0071bd" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }

    const workbook = utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Profiles");

    // Generate the Excel file and prompt the user to download it
    const timestamp = new Date().toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    writeFile(workbook, `${timestamp}_profiles.xlsx`);
  };
  const downloadAsExcel1 = () => {
    const getColumnWidths = (data: string[][]) => {
      const widths: number[] = [];
      for (let i = 0; i < data[0].length; i++) {
        const columnValues = data.map((row) => row[i]?.length || 0); // Handle undefined lengths
        widths[i] = Math.max(...columnValues);
      }
      return widths;
    };

    const headers = [
      "S.No",
      "Full Name",
      "Email",
      "Mobile",
      "Years Of Experience",
      "Technology",
      "Resume",
      "Reference Name",
      "Date",
    ];

    const modifiedProfiles = profiles.map((p, i) => ({
      ...p,
      siNo: i + 1,
    }));

    const rows = modifiedProfiles.map((p) => [
      p.siNo,
      p.fullName || "", // Ensure no undefined values
      p.emailId ? `<a href="mailto:${p.emailId}">${p.emailId}</a>` : "", // Email as hyperlink
      p.mobileNo || "",
      p.yearsOfExperience || "",
      p.technology || "",
      p.resume || "",
      p.referenceName || "", // Use empty string if undefined
      formatDateToDDMMMYYYY(new Date(p.createdDate)),
    ]);

    const allData = [headers, ...rows];
    const columnWidths = getColumnWidths(allData);

    let csvContent =
      headers
        .map((header, index) => {
          return header.padEnd(columnWidths[index]);
        })
        .join(",") + "\n";

    csvContent += rows
      .map((row) =>
        row
          .map(
            (cell, index) =>
              // Convert cell value to string and then apply padEnd
              (String(cell) || "").padEnd(columnWidths[index]) // Use String(cell) to ensure it's a string
          )
          .join(",")
      )
      .join("\n");

    const excelContent = `  
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel">  
      <head>  
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">  
        <style>  
          th { font-weight: bold; background-color: #f3f4f6; }  
          td, th { min-width: 100px; padding: 5px; }  
          tr, th, td { border: 1px solid gray; }  
        </style>  
      </head>  
      <body>  
        <table>  
          <thead>  
            <tr>  
              ${headers.map((header) => `<th>${header}</th>`).join("")}  
            </tr>  
          </thead>  
          <tbody>  
            ${rows
              .map(
                (row) => `  
              <tr>  
                ${row.map((cell) => `<td>${cell}</td>`).join("")}  
              </tr>  
            `
              )
              .join("")}  
          </tbody>  
        </table>  
      </body>  
      </html>  
    `;

    const blob = new Blob([excelContent], { type: "application/vnd.ms-excel" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    const timestamp = new Date(currentDateValue).toISOString().split("T")[0];

    link.download = `${timestamp}_profiles.xlsx`;
    document.body.appendChild(link); // Append link to body
    link.click();
    document.body.removeChild(link); // Clean up after download
  };

  const handleDeleteConfirm = async () => {
    setIsConfirmDeleteOpen(false);
    handleDeleteProfile(selectedProfileToDelete?.id);
  };
  const handleCancel = async () => {
    setIsConfirmDeleteOpen(false);
  };

  const handleDateChange = (date) => {
    // Implement what happens with the selected date
    console.log("Selected date:", date);
    // today.toISOString().split("T")[0]
    setCurrentDateValue(new Date(date).toISOString().split("T")[0]);
  };

  return (
    <>
      {isConfirmDeleteOpen && (
        <ConfirmationPopup
          message="Are you sure you want to delete the data?"
          onConfirm={handleDeleteConfirm}
          onCancel={handleCancel}
        />
      )}
      <div
        className="overflow-x-auto bg-white rounded-lg shadow"
        style={{ marginBottom: "5rem" }}
      >
        <div className="p-4 flex justify-between items-center border-b vertical-align-middle">
          <div className="flex items-center justify-between mb-4 w-full">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-500 mr-4 flex items-center">
              <span className="text-md">Scheduling Profiles</span>

              {/* <label className="cursor-pointer relative inline-flex items-center ml-4">
                <input
                  type="checkbox"
                  name="isActive"
                  checked={isActive}
                  onChange={(e) => handleActiveIsActive(e)}
                  className="sr-only"
                />
                <div className="w-14 h-8 bg-gray-300 rounded-full shadow-inner"></div>
                <div
                  className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-200 ease-in-out ${
                    isActive ? "translate-x-full bg-[#0071bd]" : "bg-gray-500"
                  }`}
                ></div>
              </label> */}
            </h2>
            <div className="flex item-center justify-center">
              <button
                disabled
                onClick={() => inportFileInputRef?.current?.click()} // Trigger hidden file input on button click
                className="mr-4 flex items-center hover:cursor-pointer justify-center p-2 border border-blue-700 bg-blue-100 rounded-lg shadow-md transition-all duration-300 ease-in-out transform  active:scale-95"
              >
                <Upload className="w-4 h-4 mr-2 transition-transform duration-300 ease-in-out" />
                <ExcelIcon className="h-6 w-6 transition-transform duration-300 ease-in-out hidden sm:flex" />
                <span className="mx-2 hidden md:inline">Import</span>
              </button>

              {/* Hidden file input */}
              <input
                type="file"
                ref={inportFileInputRef} // Reference to control the hidden input
                onChange={(e) => onImport(e)}
                accept=".xls,.xlsx" // Allow only Excel files
                className="hidden" // Hide the input
              />
              <button
                onClick={downloadAsExcel}
                disabled={profiles?.length === 0}
                className="flex items-center hover:cursor-pointer justify-center p-2 border border-green-500 bg-green-100 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-green-200 active:scale-95"
              >
                <Download className="w-4 h-4 mr-2 transition-transform duration-300 ease-in-out" />
                <ExcelIcon className="h-6 w-6 transition-transform duration-300 ease-in-out hidden sm:flex" />
                <span className="mx-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start mb-4 m-2">
          <label
            htmlFor="dateInput"
            className="block text-md font-medium text-gray-700 mb-1 mr-4 ml-2"
          >
            Select Date
          </label>
          <input
            type="date"
            id="dateInput"
            value={currentDateValue}
            onChange={(e) => handleDateChange(e.target.value)}
            className="max-w-[200px] p-2 ml-4 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
          />
        </div>

        <div className="overflow-x-auto">
          <table className=" bg-white rounded-lg shadow-md">
            <thead className="bg-gray-50">
              <tr>
                {[
                  "Full Name",
                  "Email ID",
                  "Mobile No",
                  "Years of Experience",
                  "Technology",
                  "Resume",
                  "Reference Name",
                  "Actions",
                ].map((header, i) => (
                  <th
                    key={i + 1}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {profiles.map((profile, i) => (
                <tr className="hover:bg-gray-50" key={profile.id}>
                  <td className="px-4 py-2 min-w-[4rem]">
                    {renderEditableCell(profile, "fullName", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "emailId", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "mobileNo", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "yearsOfExperience", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "technology", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "resume", i)}
                  </td>
                  <td className="px-4 py-2">
                    {renderEditableCell(profile, "referenceName", i)}
                  </td>

                  <td className="px-4 py-2">
                    <button
                      onClick={() => deleteRow(profile)}
                      className="text-red-600 hover:text-red-900 ml-2"
                    >
                      <Trash className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="hover:bg-gray-50">
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="text"
                    value={newRow.fullName}
                    onChange={(e) =>
                      handleInputChange("fullName", e.target.value)
                    }
                    className="w-full px-2 py-1 min-w-full  border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="First Name"
                  />
                </td>
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="email"
                    value={newRow.emailId}
                    onChange={(e) =>
                      handleInputChange("emailId", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Email"
                  />
                </td>
                <td className="px-4 py-2 min-w-[11rem]">
                  <input
                    type="tel"
                    value={newRow.mobileNo}
                    onChange={(e) =>
                      handleInputChange("mobileNo", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Mobile"
                  />
                </td>
                <td className="px-4 py-2 min-w-[8rem]">
                  <select
                    value={newRow.yearsOfExperience}
                    onChange={(e) =>
                      handleInputChange("yearsOfExperience", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                  >
                    <option value="">Select</option>
                    {yearsOfExperienceOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 min-w-[10rem]">
                  <select
                    value={newRow.technology}
                    onChange={(e) =>
                      handleInputChange("technology", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                  >
                    <option value="">Select Technology</option>
                    {technologyOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 min-w-12rem]">
                  <div className="flex items-center gap-2">
                    {newRow.resume ? (
                      <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-[#0071bd] rounded-lg w-full">
                        {/* Download Icon */}
                        <button
                          onClick={() =>
                            downloadFile(newRow.url, newRow.resume)
                          } // Function to download the file
                          className="text-[#0071bd] hover:text-blue-700 p-0.5 rounded-full hover:bg-blue-100"
                        >
                          <Download className="w-4 h-4" />{" "}
                          {/* Assuming 'Download' is an appropriate icon */}
                        </button>

                        <span className="flex-1 truncate text-sm">
                          {newRow.resume}
                        </span>

                        <button
                          onClick={() => removeFile()}
                          className="text-red-600 hover:text-red-900 p-0.5 rounded-full hover:bg-red-50"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => fileInputRef.current?.click()}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm text-[#0071bd] border border-[#0071bd] rounded-lg hover:bg-[#0071bd] hover:text-white transition-colors w-full"
                      >
                        <Upload className="w-4 h-4" />
                        <span>Resume</span>
                      </button>
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => handleFileUpload(e)}
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                    />
                  </div>
                </td>
                <td className="px-4 py-2 min-w-[13rem]">
                  <input
                    type="text"
                    value={newRow.referenceName}
                    onChange={(e) =>
                      handleInputChange("referenceName", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-[#0071bd]"
                    placeholder="Reference Name"
                  />
                </td>

                <td className="px-4 py-2">
                  <button
                    onClick={addNewRow}
                    className="p-2 text-[#0071bd] border border-[#0071bd] rounded-full hover:bg-[#0071bd] hover:text-white transition-colors"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DailyScheduleTable;
